.chipContainer {
  gap: 0.8rem;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.nonCarousel {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.showCarousel {
  display: flex;
  align-items: center;
}
.chipContainer::-webkit-scrollbar {
  display: none;
}

.roundChip {
  border-radius: 1.8rem !important;
}

div.selected {
  border: 0.1rem solid var(--orange-500);
  background: var(--orange-500) !important;
  color: var(--neutral-100) !important;
  font-size: 1.2rem !important;
  font-weight: 400;
}

.notAvailable {
  border: 0.1rem solid var(--gray-200);
  width: max-content;
  border-radius: 0.8rem;
  background: var(--gray-20) !important;
  color: var(--gray-400);
  font-size: 1.2rem !important;
  font-weight: 400;
  text-decoration: line-through;
  text-decoration-color: var(--gray-400);
}

.selectionDefault {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--neutral-100);
  color: var(--gray-600);
  font-size: 1.2rem !important;
  font-weight: 400;
}

.notAvailable,
.selectionDefault,
.selected {
  white-space: nowrap;
  font-family: "Poppins", sans-serif;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.clearBtn {
  border-radius: 1.8rem;
  background: var(--gray-500);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1.2rem !important;
  width: max-content;
  font-family: "Poppins", sans-serif;
}

.clearText {
  margin-left: 0.4rem !important;
  color: var(--neutral-100);
  font-size: 1.2rem !important;
  font-weight: 400;
  text-transform: capitalize;
  cursor: pointer;
}
.chipSelectHover:hover {
  background: var(--orange-500);
  color: var(--neutral-100);
}
@media screen and (max-width: 65rem) {
  .chipContainer {
    overflow: scroll;
    max-width: 100vw;
  }
  .testclassname {
    color: black;
    background-color: burlywood;
    /*max-width: 40rem;*/
  }
}
