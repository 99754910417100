html {
  font-size: 62.5%;
}
body {
  margin: 0;
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--gray-50);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.MuiPickersDay-root.Mui-selected,
.MuiPickersYear-yearButton.Mui-selected,
.MuiClock-pin,
.MuiClockPointer-root {
  background-color: var(--orange-500);
}

.MuiClockPointer-thumb {
  border: 1.6rem solid var(--orange-500);
}

.MuiDialogActions-root > button {
  color: var(--orange-500);
}
input[type="text"] {
  outline: none;
  border: none;
  width: 100%;
}

.alice-carousel__dots-item.__active {
  background: var(--orange-500) !important;
}
.alice-carousel__dots {
  margin: 0rem !important;
}
.alice-carousel__dots-item {
  background: var(--gray-400) !important;
  width: 0.6rem;
  height: 0.6rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
