.container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: var(--neutral-100);
  border-bottom: 0.1rem solid var(--gray-50);
  border-radius: 8px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}
.actionContainer {
  display: flex;
  align-items: center;
  gap: 4rem;
}
.cancelContainer {
  text-align: center;
}
.orderNameContainer {
  display: flex;
  flex-direction: column;
}

.orderType {
  color: var(--gray-400);
  font-size: 1.4rem;
  font-weight: 400;
}
.cancelText {
  margin-top: 0.2rem;
  color: var(--red-600);
  font-size: 1.4rem;
  font-weight: 400;
}
