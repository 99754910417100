.outerContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  cursor: pointer;
  width: 100%;
}

.thumbnail {
  width: 29.5rem;
  height: 16.4rem;
  border-radius: 0.8rem;
  object-fit: cover;
}

.title {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.1rem;
  text-align: left;
  font-family: "Poppins", sans-serif;
  max-width: 95%;
}

.description {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  text-align: left;
  font-family: "Poppins", sans-serif;
  color: var(--gray-500);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.metaDataContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.topContainer {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.categoryContainer {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  background-color: var(--neutral-100);
  padding: 0.2rem 1rem;
  border-radius: 2rem;
}

.category,
.date {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.8rem;
  text-align: left;
  font-family: "Poppins", sans-serif;
}

.category {
  color: var(--gray-600);
  font-weight: 500;
}

.date {
  color: var(--gray-500);
}

.readCount {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.1rem;
  text-align: left;
  color: var(--neutral-800);
  font-family: "Poppins", sans-serif;
}

@media screen and (max-width: 65rem) {
  .thumbnail {
    width: 100%;
    height: 16.4rem;
  }

  .outerContainer {
    width: 100%;
  }

  .title,
  .readCount {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  .description {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }

  .metaDataContainer {
    gap: 0.6rem;
  }
}
