.lineContainer {
  display: flex;
  justify-content: center;
}
.line {
  width: 6.4rem;
  height: 0.6rem;
  border-radius: 0.3rem;
  background: #d9d9d9;
  margin: 1rem 0rem !important;
}
.lineBottomContainer {
  background-color: var(--gray-50);
}
.lineBottom {
  width: 12rem;
  height: 0.6rem;
  border-radius: 0.3rem;
  background: var(--gray-200);
  margin: 1rem 0rem !important;
}
.closeIconContainer {
  position: absolute;
  top: -6rem;
  right: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 1.8rem;
  background: var(--neutral-100);
}
