.container {
  padding: 1.6rem 1.2rem;
  display: flex;
  align-content: center;
  border-bottom: 0.1rem solid var(--gray-20);
  cursor: pointer;
}
.templateName {
  color: var(--black-900);
  font-size: 1.4rem !important;
  font-weight: 500 !important;
}
