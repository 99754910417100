.header {
  display: flex;
  align-items: center;
}
.locationHeader {
  padding: 2rem 1.6rem;
}
.heading {
  color: var(--gray-800);
  font-size: 1.6rem;
  font-weight: 500;
}
.subHeading {
  color: var(--gray-600);
  font-size: 1.2rem;
  font-weight: 400;
  margin-top: 0.8rem;
}

.locationLabeling {
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.orangeText {
  color: var(--orange-500);
  cursor: pointer;
}
.locationAccessText {
  color: var(--gray-700);
}
.buttonContainer {
  padding: 1.2rem 1.6rem 2rem 1.6rem;
  border-top: 0.1rem solid #ebebeb;
  background: var(--neutral-100);
}

.locationMiddleText {
  margin-bottom: 2rem;
}
.locationGridContainer {
  margin: 1.2rem 0rem 0.8rem 0rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.8rem;
}
@media screen and (max-width: 65rem) {
  .locationHeader {
    padding: 1.6rem 1.6rem 0rem 1.6rem;
  }
  .buttonContainer {
    padding: 1.2rem 1.6rem 0rem 1.6rem;
    border-top: 0.1rem solid #ebebeb;
    background: var(--neutral-100);
  }
}
