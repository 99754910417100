.slotSelectionContainer {
  display: flex;
  gap: 1.4rem;
}
.checkboxBtn {
  padding: 0.8rem 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.2rem;
  min-width: 5.6rem;
  max-width: 10rem;
  border-radius: 0.8rem;
}

.unavailableSlot {
  background: var(--gray-20) !important;
}

.checkBox {
  display: none !important;
}
.checkboxItemDefault {
  border: 0.1rem solid var(--gray-200);
  background: var(--neutral-100);
}
.checkboxSelectedItem {
  border: 0.1rem solid var(--orange-500);
  border-radius: 0.8rem;
  background: var(--orange-500);
}
