.patientDetailContainer {
  text-align: left;
  padding: 1.6rem;
}
.header {
  display: flex;
  align-items: center;
  margin-bottom: 2rem !important;
}

.header > p {
  margin-left: 1rem !important;
  color: var(--black-900);
  font-size: 1.4rem !important;
  font-weight: 500 !important;
}
.slotContainer,
.clinicAddressContainer {
  display: flex;
  align-items: center;
  margin-bottom: 0.4rem !important;
}
.patientDetailContainer > h6 {
  color: var(--black-900);
  font-size: 1.4rem !important;
  font-weight: 500 !important;
  text-transform: capitalize;
  margin-bottom: 1.6rem !important;
}
.clinicAddressContainer > p,
.slotContainer > p {
  margin-left: 0.2rem !important;
  color: var(--gray-500);
  font-size: 1.2rem !important;
  font-weight: 400 !important;
}
.clinicAddressContainer {
  margin-bottom: 1.2rem !important;
}
.line {
  border: 0.1rem solid var(--gray-200) !important;
}
.editMobileNumber {
  color: var(--orange-500);
  font-size: 1.4rem !important;
  font-weight: 500;
  background-color: var(--neutral-100);
}
.formPatientInputField {
  margin: 0rem 0rem 1.6rem 0rem !important;
}

.staffNoAvailService {
  margin-top: 1.6rem !important;
  color: var(--gray-400);
  font-size: 1.2rem !important;
  font-weight: 400 !important;
}
