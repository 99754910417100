.cardContainer {
  border-radius: 0.6rem;
  background: var(--neutral-100);
  padding: 1.2rem 1.6rem;
  width: 100%;
}
p.cardTitle {
  color: var(--orange-500);
  font-size: 1.4rem;
  font-weight: 500;
}
p.cardSubHeading {
  color: var(--black-900);
  font-size: 1.2rem;
  font-weight: 400;
}
.titleContainer {
  margin-top: 0.8rem;
}
.cardIcon > svg {
  width: 2rem;
  height: 2rem;
}
