.accordionContainer {
  box-shadow: none;
  border-radius: 0rem;
}

.subTitle {
  margin-top: 0.4rem !important;
  color: var(--gray-400);
  font-size: 1.4rem !important;
  font-weight: 400 !important;
}
