.schedulePopUpContainer {
  padding: 1.6rem;
}
.schedulePopUpContainer > h4 {
  color: var(--black-900);
  font-size: 2rem !important;
  font-weight: 500 !important;
}
.schedulePopUpContainer > h4 > span {
  color: var(--gray-400);
  font-size: 1.4rem !important;
  font-weight: 400;
}
.patientName {
  margin: 0.8rem 0rem !important;
  color: var(--black-900);
  font-size: 1.6rem !important;
  font-weight: 400 !important;
}
.patientAge {
  margin: 0rem 0rem 0.8rem 0rem !important;
  color: var(--gray-400);
  font-size: 1.4rem !important;
  font-weight: 400 !important;
}

.medicalRecordContainer {
  margin-top: 1.6rem !important;
  padding: 1.2rem 1.6rem 0.8rem 1.6rem;
  height: 4.8rem;
  border-radius: 0.8rem;
  background: var(--warm-neutral-50);
}
.medicalRecordContainer,
.medicalRecordCounter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.medicalRecordCounter > p {
  margin-left: 0.4rem !important;
  color: var(--gray-700) !important;
  font-size: 1.4rem;
  font-weight: 400;
  text-transform: capitalize;
}
.medicalRecordShow {
  color: var(--orange-500);
  font-size: 1.4rem !important;
  font-weight: 500;
  text-transform: capitalize;
}
.actionsBtnContainer > button {
  margin-top: 1.6rem !important;
}
