.actionText {
  color: var(--gray-700);
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 2rem;
}
.actionTextWhite {
  color: var(--neutral-100);
}
.actionEventTriggerContainer {
  padding: 1rem 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.4rem;
  cursor: pointer;
}

.joinVideoCall {
  margin: 0rem;
  padding: 1rem 3.6rem;
  background-color: var(--orange-500);
  border-top-right-radius: 1rem;
}

.btnLayer {
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 1.2rem;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

div.spacing {
  margin: 0rem 1.2rem;
}
