div.card {
  border-radius: 0.8rem;
  background-color: var(--gray-20);
  padding: 1.2rem 0.8rem 0.8rem;
  width: 9rem;
  text-align: left;
}

.checkoutRadioButton {
  margin-top: -1.1rem;
}
div.checkoutCard {
  height: 10.8rem;
}
div.homeCard {
  height: 8.8rem;
}
p.patientName {
  color: var(--gray-500);
  font-size: 1.4rem;
  font-weight: 400;
}
.homeRadioButton {
  margin-right: 0rem;
}
p.patientAge {
  display: flex;
  align-items: center;
  color: var(--gray-500);
  font-size: 1.2rem;
  font-weight: 600;
}
p.patientAgeHome {
  margin-top: 0.8rem;
}
div.radioWrapper {
  margin-left: 0.8rem;
}
.clickHere {
  margin-top: 0.8rem;
  color: var(--orange-500);
  font-size: 1.2rem;
  font-weight: 500;
}
@media screen and (max-width: 65rem) {
  div.homeCard {
    height: 8.8rem;
    width: 100%;
  }
}
