.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0rem;
  width: 100%;
  z-index: 100;
  position: sticky;
}

.backNav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}
.heading {
  color: var(--black-900);
  font-size: 1.6rem !important;
  font-weight: 500 !important;
}
.subHeading {
  color: var(--gray-400);
  font-size: 1.4rem !important;
  font-weight: 400 !important;
  line-height: 99.538%;
}
.firstColumnWrapper {
  display: flex;
  align-items: center;
}

div.actionBox {
  margin-left: 0.9rem;
  cursor: pointer;
}
