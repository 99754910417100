.lineContainer {
  position: fixed;
  width: 100%;
  bottom: 0rem;
  z-index: 100;
  display: flex;
  justify-content: center;
  background-color: transparent;
}
.lineBottom {
  width: 12rem;
  height: 0.6rem;
  border-radius: 0.3rem;
  margin: 1rem 0rem !important;
}
