.dayText {
  color: var(--gray-400);
  text-align: center!;
  font-size: 1.2rem !important;
  font-weight: 400 !important;
}
.dateText {
  color: var(--gray-800);
  text-align: center;
  font-size: 1.2rem !important;
  font-weight: 600 !important;
}

.dateText.unavailable {
  color: var(--gray-500);
  text-decoration: line-through;
}
