:root {
  --swiper-navigation-color: var(--orange-500);
  --swiper-pagination-color: var(--orange-500);
}
.App {
  text-align: center;
}
button > .rdrStartEdge,
button > .rdrInRange,
button > .rdrEndEdge {
  border-radius: 1.8rem;
  background: var(--orange-500);
}

.rdrDayToday .rdrDayNumber span:after {
  background: var(--orange-500);
}
.rdrDefinedRangesWrapper,
.rdrDateDisplayWrapper {
  display: none;
}
.dateRangePickerContainer {
  position: absolute;
  z-index: 100;
  background-color: var(--neutral-100);
  box-shadow: 0rem 0.4rem 1rem rgba(0, 0, 0, 0.2);
  border-radius: 1rem !important;
  /* left: 0; */
}

.rdrCalendarWrapper {
  border-radius: 1rem !important;
  background-color: var(--neutral-100);
}
.clearBtn > button {
  margin-right: 0.5rem;
}

#portal-root {
  position: fixed;
  inset: 0;
  z-index: 100000;
  min-height: 100vh;
  pointer-events: none;
}

.applyBtn > button,
.clearBtn > button {
  width: auto;
  height: 3rem;
}
.applyBtn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  padding: 0rem 1rem 1rem !important;
}

/* @media screen and (min-width: 900px) {
  .dateRangePickerContainer {
    left: auto;
    right: 40%;
  }
} */
