:root {
  --orange-500: #f15a29;
  --gray-700: #393939;
  --gray-350: #898991;
  --gray-50: #f2f6f7;
  --neutral-100: #ffffff;
  --neutral-400: #ffffff80;
  --neutral-500: #ffffff85;
  --neutral-300: #f5f5f5;
  --neutral-200: #f6f6f6;
  --neutral-800: #696969;
  --neutral-900: #898991;
  --black-800: #00000050;
  --black-900: #000000;
  --gray-10: #838383;
  --gray-100: #f2f6f7;
  --gray-20: #f4f4f4;
  --gray-30: #a8a8a8;
  --gray-300: #c6c6c6;
  --gray-400: #8d8d8d;
  --gray-500: #6f6f6f;
  --gray-600: #525252;
  --gray-800: #161616;
  --gray-900: #262626;
  --green-50: #e2ffe9;
  --green-500: #019c36;
  --green-400: #02b240;
  --red-50: #ffefef;
  --red-400: #de1c1c;
  --warm-neutral-50: #fff6ea;
  --cream-500: #ffe4c3;
  --cream-100: #fef3ef;
  --gray-200: #e0e0e0;
  --warm-neutral-100: #ffedd6;
  --green-600: #30b003;
  --brown-900: #211b19;
  --red-600: #c12323;
  --red-100: #ffe9e2;
  --red-900: #e60505;
  --blue-900: #1f2044;
  --blue-50: #ecfbff;
  --dark-500: #292323;
  --blue-500: #0f2851;
  --teal-500: #7bb4c2;
  --blue-100: #f0f8ff;
  --blue-gray-100: #dee5e6;
  --peach-100: #ffe2be;
  --peach-200: #ffe4c3;
  --peach-300: #fff6ea;
  --orange-50-transparent: #f15a291a;
}
