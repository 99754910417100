.actionButtonsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.2rem;
  position: fixed;
  bottom: 0rem;
  left: 0;
  right: 0;
  padding: 1rem;
  background-color: var(--neutral-100);
}

.actionButtonsWrapper {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  background-color: var(--gray-200);
  padding: 0.8rem 0.6rem;
  border-radius: 2.6rem;
}

.actionButton {
  border-radius: 30px;
  height: 3.2rem;
  width: 3.2rem;
  padding: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--neutral-100);
  cursor: pointer;
}

.likeButton {
  composes: actionButton;
  width: 5.8rem;
  height: 3.2rem;
  padding: 0.4rem 0.6rem;
}

.actionButton > span {
  font-weight: 400;
  font-size: 1.2rem;
  color: var(--gray-700);
  line-height: 1.8rem;
  margin-left: 0.4rem;
}

.favourited {
  background-color: var(--orange-500) !important;
}

.favourited > svg {
  fill: white;
}

.liked {
  background-color: var(--orange-500) !important;
}

.liked > span {
  color: white !important;
}

.liked > svg {
  fill: white;
}

.favouriteIcon,
.likeIcon,
.shareIcon {
  color: var(--orange-500);
}
