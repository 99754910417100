.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2.5rem;
  padding-bottom: 0;
}
.capturePrescription {
  display: flex;
  align-items: center;
  align-content: center;
  height: 2.4rem;
  justify-items: center;
}
.capturePrescription > p {
  color: var(--orange-500);
  font-family: "Poppins", sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  border-color: rgb(224, 224, 224);
  padding-top: 0.1rem;
}

.vitalsContainer {
  padding: 0rem 1.6rem;
}

.healthCategoriesContainer {
  width: 100%;
  box-sizing: border-box;
}

.medicineTestContainer {
  width: 100%;
}

.vitalsInputsContainer {
  margin-top: 1.35rem;
  display: flex;
  justify-content: start;
  align-items: left;
  flex-wrap: wrap;
  gap: 1.6rem;
}

.vitalsInputsContainer > div > div {
  width: 16rem;
  height: 6.3rem;
}

.vitalsInputsContainer > div > div > input {
  width: 100%;
  height: 2.1rem;
  display: flex;
  line-height: 1.6rem;
  padding-bottom: 0rem;
  margin-bottom: 0rem;
}

.vitalsInputsContainer > div > label {
  line-height: 1.6rem;
  font-size: 1.6rem;
  height: 2.6rem;
  margin-block-start: 1.2rem;
}

.inputContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1.6rem;
  width: 100%;
}

.innerInputContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  width: 100%;
}

.savePrescription {
  margin-right: 1rem;
  width: 10%;
  display: flex;
  justify-items: center;
  align-items: center;
}

.saveTemplate {
  margin-right: 1rem;
  width: fit-content;
  display: flex;
  justify-items: center;
  align-items: center;
}

.endPrescription {
  display: flex;
  justify-items: center;
  align-items: center;
  padding-inline: 0.2rem;
}
.endPrescription > button {
  width: 100%;
  padding-inline: 0.2rem;
  padding: 0rem 2rem;
  gap: 0.4rem;
}
.cameraImageContainer {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  background-color: var(--neutral-100);
}

.capturedImageContainer {
  margin-top: 0.8rem;
  overflow: hidden;
  border-radius: 1.2rem;
  height: 67%;
}

.capturedImageContainer > img {
  width: 100%;
  height: 100%;
  padding: 1.6rem 1.6rem 0rem 1.6rem;
  box-sizing: border-box;
  border-radius: 3.2rem;
}

.categoryContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.actionBtnContainer {
  display: flex;
  bottom: 0rem;
  width: 100%;
  padding: 1.6rem 1.6rem 2.6rem 1.6rem;
}
.topActionBtnContainer {
  display: flex;
  bottom: 0rem;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--gray-50);
}

.cameraPrescriptionActionBtns {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
}

.addModalContainer {
  padding: 2.5rem;
  width: 40rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
}

.nextVisitTypeSelector {
  display: flex;
  width: 100%;
}

.title {
  font-size: 1.6rem;
  font-weight: 500;
  text-align: left;
}
.uploadButton {
  width: 100%;
}
.uploadButton > button {
  font-size: 1.5rem;
}
.saveButton > button {
  font-size: 1.4rem;
  margin-bottom: 4rem;
}
.previewFileSection {
  padding: 1rem 1rem 0.5rem 1rem;
  background-color: var(--warm-neutral-50);
  border-radius: 1.6rem;
  width: 100%;
  box-sizing: border-box;
}

.previewFileSection > img {
  width: 100%;
  height: 24.5rem;
  border-radius: 0.5rem;
  object-fit: cover;
}

.changeImageButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.changeImageButtonContainer > button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.1rem;
  color: var(--orange-500);
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.browseTemplateBox {
  color: var(--orange-500);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2.1rem;
  width: 20.8rem;
  height: 4.8rem;
}

.browseTemplateBox > fieldset {
  border-color: var(--gray-200) !important;
}
.browseTemplateBox > svg {
  display: none;
}

.browseTemplateBox > div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem !important;
}

.browseTemplateimage {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  background-color: var(--neutral-100);
}

.formControlbox {
  width: 100%;
  height: 4.8rem;
  border-color: var(--gray-200);
  background-color: var(--neutral-100);
  border-radius: 1.2rem;
}

@media only screen and (max-width: 768px) {
  .container {
    padding-bottom: 10rem;
  }
  .vitalsInputsContainer > div > div {
    width: 9.86rem;
    height: 6.3rem;
  }
  .savePrescription {
    width: 25%;
  }
  .endPrescription {
    flex: 1;
  }
}
