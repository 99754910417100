.actionsContainer {
  display: flex;
  gap: 1.2rem;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.actionButton {
  border: none;
  border-radius: 3rem;
  background-color: var(--neutral-100);
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: var(--black-900);
}

.actionButton:hover {
  background-color: var(--orange-500);
  color: var(--neutral-100);
  box-shadow: none;
}

.actionText {
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.1rem;
}

.liked {
  background-color: var(--orange-500);
  color: var(--neutral-100);
}

.favourited {
  background-color: var(--orange-500);
  color: var(--neutral-100);
}
