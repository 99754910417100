.medicalRecordContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  gap: 0.1rem;
}
.recordContainer {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  width: 100%;
}
.emptyRecordContainer {
  display: flex;
  width: 100%;
}
.recordCount {
  padding: 0rem 0rem 0rem 1.6rem;
  margin-bottom: 0.8rem;
  color: #8b8b8b;
  font-size: 1.2rem;
  font-weight: 600;
}
.recordViewOptions {
  display: flex;
  align-items: center;
}
.recordViewOptions > p {
  color: var(--orange-500);
  font-size: 1.6rem;
  font-weight: 400;
}
.medicalRecordFilterContainer {
  padding: 1.2rem 0rem 0rem 1.2rem;
}
.medicalRecordHeaderContainer {
  position: sticky;
  top: 0rem;
  z-index: 100;
  background-color: var(--gray-50);
}
.medicalFilterRecordCountContainer {
  background-color: var(--gray-20);
}
.appointmentBannerMedicalRecordCountContainer {
  padding: 0rem 1.6rem;
  margin-top: 1.2rem;
}
.medicalRecordCountContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.medicalRecordHeading {
  color: var(--black-900);
  font-size: 1.4rem;
  font-weight: 500;
}
.appointmentBanner {
  padding: 1.6rem 1.6rem 0rem 1.6rem;
  margin: 0rem 0rem 2rem 0rem;
  display: flex;
  justify-content: space-between;
  border-radius: 1.2rem;
  background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.2) 100%
    ),
    linear-gradient(99deg, #a8cdf3 14.77%, #c5dff9 98.04%);
}
.appointmentBannerTextDetail {
  width: 50%;
}
.appointmentBannerTextDetail > h3 {
  color: var(--blue-900);
  font-size: 1.4rem;
  font-weight: 600;
}
.appointmentBannerTextDetail > p {
  margin: 0.4rem 0rem;
  color: var(--dark-500);
  font-size: 1rem;
  font-weight: 400;
}
.appointmentBannerTextDetail > button {
  border-radius: 1.2rem;
  border: none;
  padding: 0.4rem 1rem;
  background: var(--blue-900);
  color: var(--neutral-100);
  font-size: 1rem;
  font-weight: 400;
}
.activePrescriptionBtn {
  width: 100%;
  padding: 0rem 1.6rem;
  position: fixed;
  bottom: 2.8rem;
  z-index: 100;
}
.sharebuttons {
  height: 2.8rem;
  width: 2.8rem;
  border-radius: 100rem;
  background-color: var(--neutral-100);
}
.category {
  padding: 0.4rem 0.8rem;
  border-radius: 1.2rem;
  background-color: var(--neutral-100);
  display: flex;
  gap: 0.4rem;
  align-items: center;
}
.categoryName {
  color: var(--black-900);
  font-family: Roboto;
  font-size: 1.4rem;
  text-transform: capitalize;
  font-weight: 600;
}
.headerBox {
  position: absolute;
  top: -4.5rem;
  left: 0;
  right: 0;
  height: 4.5rem;
  background-color: var(--gray-200);
  z-index: 1;
}

.headerGrid {
  display: flex;
  padding-inline: 1.6rem;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.shareButtonsContainer {
  display: flex;
  gap: 1.2rem;
  align-items: center;
}

.recordInfoContainer {
  display: flex;
  align-items: center;
  gap: 1.2rem;
}

.recordTypeContainer {
  display: flex;
  gap: 0.4rem;
}

.recordText {
  font-size: 1.4rem;
  font-weight: 400;
}

.img-wrap {
  width: 100%;
  object-fit: contain;
  aspect-ratio: 16/9;
}
.chipSelectWeb {
  padding: 1.2rem 0rem 0.8rem 1.2rem;
  background-color: var(--blue-gray-100);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  width: 100%;
  height: 5.6rem;
}
.medicalrecordsweb {
  overflow-y: scroll;
  margin-top: 5.6rem;
}
