.listItem {
  display: flex;
  align-items: start;
  justify-content: flex-start;
  gap: 1.5rem;
  background-color: var(--neutral-100);
  cursor: pointer;
}

.title {
  font-family: "Poppins", sans-serif;
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-weight: 500;
  color: var(--gray-600);
}

.type {
  color: var(--gray-600);
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 400;
}

.imgContainer img {
  width: 8.2rem;
  height: 4.6rem;
  border-radius: 0.8rem;
  object-fit: cover;
}
