.shareDialog {
  margin: 2rem;
  display: flex;
  flex-direction: column;
}

.shareTitle {
  font-size: 2.2rem;
  font-weight: 600;
  color: var(--gray-900);
  font-family: "Poppins", sans-serif;
  line-height: 1.5rem;
}

.shareOption {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 1rem;
  margin-top: 1rem;
}

.inputContainer {
  width: 100%;
  padding: 0.5rem 1rem;
  background-color: var(--gray-200);
  display: flex;
  justify-content: flex-start;
  flex: 1;
  flex-wrap: nowrap;
  overflow: hidden;
  border-radius: 0.5rem;
}

.inputContainer div {
  font-size: 1.3rem;
  font-weight: 400;
  color: var(--gray-900);
  font-family: "Poppins", sans-serif;
  overflow: hidden;
  white-space: nowrap;
}

.copyLink {
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: var(--gray-200);
  padding: 1rem;
  border-radius: 0.5rem;
  margin-top: 2rem;
}

.copyLink button {
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--gray-900);
  font-family: "Poppins", sans-serif;
  outline: 1rem;
  border: none;
  border-radius: 44rem;
  padding: 0.5rem 1rem;
  background-color: var(--orange-500);
  color: var(--neutral-100);
  cursor: pointer;
}

.iconName {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--gray-900);
  font-family: "Poppins", sans-serif;
}

.icon {
  fill: var(--neutral-100);
}

.copyLink {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media screen and (max-width: 65rem) {
  .icon {
    font-size: 3rem;
  }
}
