.removeTestBtn {
  color: var(--orange-500);
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  line-height: 1.8rem;
  text-transform: lowercase;
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 0rem;
}

.testCardContainer:not(:first-child) {
  border-top: 1px solid var(--gray-200);
}

.testCardContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.6rem;
  cursor: pointer;
}

.noteContainer {
  padding: 0.4rem 0.8rem;
  background-color: var(--gray-50);
  border-radius: 0.8rem;
  text-align: left;
}
.note {
  font-size: 1.2rem;
  line-height: 2rem;
  font-weight: 400;
  color: var(--gray-700);
  font-family: "Poppins", sans-serif;
}

.testName {
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-weight: 500;
  color: var(--gray-800);
  font-family: "Poppins", sans-serif;
}
