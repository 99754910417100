.browseTemplateimage > span {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2.1rem;
  color: var(--orange-500);
}

.browseTemplateContainer {
  background-color: var(--neutral-100);
  width: 20.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border-radius: 0.6rem;
  border: 0.1rem solid var(--gray-200);
  cursor: pointer;
  border-radius: 0.8rem;
  height: 4.8rem;
}

.browseTemplateimage {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  height: auto;
  gap: 0.8rem;
}

.browseTemplateBox {
  flex-direction: column;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2.1rem;
  background-color: var(--neutral-100);
  border-radius: 1.2rem;
  overflow: hidden;
  width: max-content;
  transition: all 0.3s ease-in-out;
  interpolate-size: allow-keywords;
}

.modalTemplateList {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.item,
.selectedItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.8rem;
  padding: 1rem 2.5rem 1rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.item:not(:last-child) {
  border-bottom: 0.1rem solid var(--gray-200) !important;
}

.item:hover {
  background-color: var(--gray-200);
}

.selectedItem {
  background-color: var(--warm-neutral-50);
}
