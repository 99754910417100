.container {
  padding: 1.6rem !important;
  margin-top: 1.6rem !important;
  border-radius: 0.8rem;
  border-bottom: 0.1rem solid var(--gray-20);
  background: var(--neutral-100);
  text-align: justify;
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
  box-sizing: border-box;
}
.medicineTestNameContainer > p {
  color: var(--gray-800);
  font-size: 1.4rem !important;
  font-weight: 500 !important;
}
.medicineTestActionContainer {
  display: flex;
  justify-content: space-between;
  gap: 0.8rem;
}
.medicineActionContainer {
  display: flex;
  flex-direction: column !important;
  gap: 1rem !important;
}
.mealTime,
.doseDays {
  color: var(--black-900);
  font-size: 1.2rem !important;
  font-weight: 400 !important;
  border-radius: 0.8rem;
  border: 0.1rem solid var(--gray-200);
  background: var(--neutral-100);
  padding: 0.8rem 1.2rem;
  cursor: pointer;
}
.addNote > input {
  color: var(--gray-400);
  border-radius: 0.8rem;
  outline: none;
  border-bottom: 0.1rem solid var(--gray-20);
  background: var(--gray-20);
  padding: 0rem 0.8rem;
}

.trashIcon {
  cursor: pointer;
  height: fit-content;
}

.mealTimeSelectionContainer,
.doseFreqSelectionContainer {
  position: absolute;
  z-index: 100;
}

.addNote > textarea {
  border: none;
  outline: none;
  resize: none;
  overflow: hidden;
  background: var(--gray-50);
  font-family: "Poppins", sans-serif;
  padding: 0.4rem 0.8rem;
  border-radius: 0.8rem;
  width: 21.6rem;
}
