.container {
  padding: 1.2rem 1.6rem;
  border-bottom: 0.1rem solid var(--gray-20);
}
.pdfContainer {
  margin-top: 0.8rem;
  display: flex;
  align-items: center;
  padding: 0.8rem 1.6rem;
  border-radius: 1.8rem;
  background: var(--gray-50);
  width: min-content;
}
.pdfContainer > p {
  margin-left: 0.4rem;
  color: var(--gray-500);
  font-size: 1.4rem;
  font-weight: 400;
}
.pdf {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.nameDate {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.name {
  color: var(--black-900);
  font-size: 1.4rem;
  font-weight: 400;
  text-transform: none;
}
.date {
  color: var(--gray-500);
  font-size: 1.2rem;
  font-weight: 400;
  text-transform: none;
}
.address,
.ageGenderContainer > p {
  color: var(--gray-400);
  font-size: 1.2rem;
  font-weight: 400;
  text-transform: none;
}
.pdf > p {
  color: var(--gray-900);
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: none;
}

.active {
  background-color: var(--warm-neutral-50);
}
