.heading {
  color: var(--gray-700);
  font-size: 1.6rem;
  font-weight: 500;
}
.content {
  padding: 0rem;
}
.content > p {
  margin: 1.2rem 0rem 2.4rem 0rem;
  color: var(--gray-700);
  text-align: center;
  font-size: 1.4rem;
  font-weight: 400;
}
.content > p > span {
  color: var(--gray-700);
  font-size: 1.4rem;
  font-weight: bold;
}
.actionBtnsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.actionBtnsContainer > div {
  width: 100%;
}
div.closePopBtn {
  margin-left: 1.7rem;
}
