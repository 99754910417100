.outerContainer {
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.viewAllText {
  color: var(--orange-500);
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 3.6rem;
  cursor: pointer;
}

@media screen and (max-width: 65rem) {
  .viewAllText {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}
