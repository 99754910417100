.saveTemplateActionContainer {
  position: relative;
  bottom: 0rem;
  z-index: 100;
  width: 100%;
  padding: 2rem 3.2rem;
  display: flex;
  justify-content: flex-end;
}
.saveTemplateActionContainer > div {
  margin-right: 1rem;
}
.saveTemplateActionContainer > button {
  width: 18%;
}
