.inputFieldContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.4rem;
  background-color: var(--neutral-100);
  border-radius: 0.8rem;
  padding: 0.8rem 1.2rem !important;
  width: 100%;
}

.inputFieldContainer > label {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.8rem;
  font-family: "Poppins", sans-serif;
  color: var(--gray-500);
}

.inputFieldContainer > input {
  width: 100%;
  padding: 0.8rem 0rem;
  border: 0.1rem solid var(--neutral-100);
  border-radius: 0.8rem;
  box-sizing: border-box;
  border: none;
  outline: none;
  font-family: "Poppins", sans-serif;
  font-size: 1.4rem;
  line-height: 2.1rem;
}

.inputFieldContainer > input:focus {
  border: none;
  outline: none;
}

.inputFieldContainer > input::placeholder {
  color: var(--gray-500);
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.8rem;
  font-family: "Poppins", sans-serif;
}

.trashIcon {
  cursor: pointer;
  height: fit-content;
}
