.heading {
  display: flex;
  align-items: center;
  color: var(--black-900);
  font-size: 1.6rem;
  font-weight: 400;
}
.inputContainer {
  margin: 1.6rem 0rem;
}
.inputBackground {
  height: 6.3rem;
  width: 17.45rem;
  border-radius: 0.8rem;
  border-bottom: 0.1rem solid var(--gray-200);
}
