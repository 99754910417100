.container {
  padding: 1.6rem 0rem 2.4rem 0rem;
  text-align: left;
}
.scheduleTitle {
  margin: 1rem 0rem 0.8rem 0rem !important;
  color: var(--black-900);
  font-size: 1.4rem !important;
  font-weight: 500;
  text-align: center;
}
.tabInnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tabInnerContainer > p {
  margin-left: 0.8rem !important;
  font-size: 1.4rem !important;
}
.tabBtnContainer {
  margin: 0rem 1.6rem !important;
  border-radius: 0.8rem;
  border: 0.1rem solid var(--gray-200);
}
.actionBtnsContainer {
  margin: 0rem 1.6rem 0rem 1.6rem !important;
}
.slotFeeText {
  margin: 2.4rem 0rem 0.8rem 0rem !important;
  color: var(--gray-500);
  font-size: 1.4rem !important;
  font-weight: 500;
}
.slotPrice {
  margin-left: 0.8rem !important;
  color: var(--gray-500);
  font-size: 1.2rem !important;
  font-weight: 400;
}
.videoCallAppointInnerContainer {
  margin: 2.4rem 1.6rem 2.4rem 1.6rem !important;
}
.dateSelectionContainer {
  margin-top: 1.2rem !important;
}
.inClinicContainer {
  padding: 2.6rem 1.6rem !important;
  min-height: 32.8rem;
  height: 32.8rem;
  overflow-y: scroll;
}
.clinicName {
  color: var(--gray-500);
  font-size: 1.4rem !important;
  font-weight: 500 !important;
  text-transform: capitalize;
}
.priceBranch {
  margin: 0.4rem 0rem 1.6rem 0rem !important;
  color: var(--gray-500);
  font-size: 1.2rem !important;
  font-weight: 400 !important;
}
.clinicInfoWrapper {
  margin-bottom: 1.6rem !important;
}
.mapText {
  margin-left: 0.6rem !important;
  color: var(--orange-500);
  font-size: 1.4rem !important;
  font-weight: 500 !important;
}
.clinicDetailContainer,
.directionIcon {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.slotsContainer {
  min-height: 32.8rem;
  height: 32.8rem;
  overflow-y: scroll;
}

@media screen and (max-width: 650px) {
  .container {
    padding: 0rem;
    width: 100%;
  }
}
