.bannerContainer {
  background-image: url("../../assets/request_callback.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
  padding: 1.6rem 2rem;
  border-radius: 1.2rem;
  border: 0.2rem solid var(--neutral-100);
}
.bannerItem {
  width: 60%;
}
.bannerTitle {
  color: var(--orange-500);
  font-size: 1.4rem !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.bannerDesc {
  color: var(--black-900);
  font-size: 1rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0.8rem 0rem !important;
}
