.searchContainer {
  padding: 0.8rem 0rem !important;
}
.foundRecordCountWithClearBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.recordCount {
  color: var(--gray-500);
  font-size: 1.2rem !important;
  font-weight: 500 !important;
}
.clearSearch {
  color: var(--orange-500);
  font-size: 1.4rem !important;
  font-weight: 400 !important;
  cursor: pointer;
}
.searchedRecordSelectionContainer {
  margin: 0.8rem 0rem !important;
}
.searchedRecordSelectionContainer > div {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.8rem;
}
