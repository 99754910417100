.outerContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.title,
.subtitle {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.subtitle {
  color: var(--black-900);
  opacity: 0.6;
  font-weight: 400;
}
