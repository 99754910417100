.container {
  padding: 1.6rem;
  border-bottom: 0.1rem solid var(--gray-200);
  background: var(--neutral-100);
  cursor: pointer;
}
.availabilityCommon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.titleIconContainer {
  display: flex;
  align-items: center;
}
.headerText {
  margin-left: 0.4rem !important;
  color: var(--black-900);
  font-size: 1.4rem !important;
  font-weight: 400 !important;
}
.footerText {
  margin-top: 0.95rem !important;
  color: var(--gray-400);
  font-size: 1.2rem !important;
  font-weight: 400;
}
