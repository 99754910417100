.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--black-900);
  position: relative;
  z-index: 100000;
  pointer-events: auto;
}

.cameraView {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  z-index: 99;
}

.videoFeed {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hidden {
  display: none;
}

.imageCaptureContainer {
  position: absolute;
  bottom: 2.5rem;
  z-index: 100;
}
.switchCamera {
  position: absolute;
  right: 1.6rem;
  bottom: 2.5rem;
}
.bottomLine {
  position: absolute;
  bottom: 1rem;
  z-index: 100;
  border-radius: 0.3rem;
  width: 12rem;
  height: 0.6rem;
  background: var(--gray-200);
}
.arrowBackBtn {
  border-radius: 1.8rem;
  background: rgba(255, 255, 255, 0.2);
  width: 3.6rem;
  height: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 1.6rem;
  left: 1.6rem;
  z-index: 100;
}
