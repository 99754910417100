.container {
  padding: 1.6rem;
}
.patientDetail {
  margin-bottom: 0.8rem !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.admittedDateDuration {
  color: var(--gray-400);
  font-size: 1.4rem !important;
  font-weight: 400 !important;
  display: flex;
  align-items: center;
}
.caseHistory {
  margin: 0.8rem 0rem !important;
}
.caseHistory > p,
.dischargedDate > p {
  color: var(--gray-400);
  font-size: 1.4rem !important;
  font-weight: 400 !important;
}
.dischargedDate > p {
  margin-bottom: 1rem !important;
}
.sponsors {
  display: flex;
}
.sponsors > p {
  color: var(--gray-700);
  text-align: right;
  font-size: 1.4rem !important;
  font-weight: 400 !important;
}
.clinicTeamTitle {
  margin: 2.2rem 0rem !important;
}
.clinicTeams {
  margin-bottom: 2rem !important;
}
