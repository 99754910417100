.breadcrumb {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.text,
.separator {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.1rem;
  color: var(--gray-600);
}

.text,
.current,
.separator {
  font-family: "Poppins", sans-serif;
}

.text {
  cursor: pointer;
}

.current {
  font-size: 1.4rem;
  font-weight: 500 !important;
  line-height: 2.1rem;
  color: var(--gray-900);
  cursor: default;
}

.separator {
  margin: 0 0.5rem;
  color: #b0b0b0;
}

.current {
  font-weight: bold;
  color: #000;
}

.iconContainer {
  background-color: var(--neutral-100);
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.textContainer {
  display: flex;
  align-items: center;
}

.goBackIcon {
  cursor: pointer;
}
