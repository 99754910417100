.container {
  border-radius: 1.2rem;
  background: var(--neutral-100);
  box-shadow: 0rem 0.4rem 1rem rgba(0, 0, 0, 0.2);
  margin-top: 1rem;
  max-width: 32rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.mealTypeChipContainer,
.doseFreqeChipContainer {
  margin: 1.6rem 0rem 1.2rem 0rem !important;
}
.mealTimeSelection,
.doseFreqSelection {
  padding: 0.8rem !important;
}
.saveCancelActionContainer > div {
  border-radius: 0rem 0rem 0.8rem 0.8rem;
  padding: 0.8rem 1.2rem !important;
}
.saveCancelActionContainer > div > div > button,
.saveCancelActionContainer > div > button {
  width: 10rem;
}
.inputBtnContainer {
  margin-top: 1.2rem !important;
  width: 100%;
}
.nextVisitTypeSelector {
  margin-top: 1.6rem !important;
}
.inputBtnContainer > input {
  color: var(--gray-400);
  border-radius: 0.8rem;
  outline: none;
  border: none;
  width: 100%;
  background: var(--gray-20);
  padding: 0.8rem;
  box-sizing: border-box;
}
