.cardContainer {
  margin-bottom: 1.6rem;
  padding: 2.2rem;
  border-radius: 1.2rem;
  background: var(--neutral-100);
}
.doctorCardAvailabilityOptionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.doctorDetailContainer {
  width: 50%;
}
.ratingContainer {
  padding: 0.6rem 0.6rem 0.6rem 0.8rem;
  border-radius: 1.2rem;
  background: var(--warm-neutral-50);
}
.ratingHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.ratingText {
  color: var(--gray-500);
  font-size: 1.2rem;
  font-weight: 500;
}
.reviewText {
  margin-top: 0.6rem;
  font-weight: 400;
}
.availabilityBtns {
  margin-top: 1.2rem;
  margin-bottom: 0.6rem;
  display: flex;
  width: 50%;
}
.availabilityVideo {
  margin-right: 0.8rem;
}
.addressText {
  color: var(--gray-500);
  font-size: 1.2rem;
  font-weight: 400;
}
.addressContainer {
  display: flex;
  align-items: center;
  margin-block: 1rem;;
}
.doctorPriceTimeLabel {
  font-size: 1.3rem;
  margin-top: 0.4rem;
  font-weight: 500;
  text-align: center;
}

.doctorPrice {
  color: var(--gray-500);
}
.doctorTime {
  color: var(--green-500);
}
.doctorCallbackBanner {
  margin-bottom: 1.6rem;
}
@media screen and (max-width: 650px) {
  .doctorCardAvailabilityOptionContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  .doctorDetailContainer {
    width: 100%;
  }
  .availabilityBtns {
    width: 100%;
  }
}
