.container {
  padding: 1.6rem;
  overflow-y: scroll;
}
.container > p,
.addReportContainer > p,
.healthCategoryContainer > p {
  color: var(--black-900);
  font-size: 1.4rem !important;
  font-weight: 500 !important;
  text-transform: capitalize;
}
.healthCategoryContainer > p {
  margin: 2.4rem 0rem 0.8rem 0rem !important;
}
.addReportContainer > p {
  margin: 2rem 0rem 0.8rem 0rem !important;
}
.uploadReportBtns > button {
  margin-bottom: 1.6rem !important;
}
.scheduleTitle {
  margin: 1rem 0rem 0.8rem 0rem !important;
  color: var(--black-900);
  font-size: 1.4rem !important;
  font-weight: 500;
  text-align: center;
}
.tabBtnContainer {
  border-radius: 0.8rem;
  border: 0.1rem solid var(--gray-200);
}
.tabInnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tabInnerContainer > p {
  margin-left: 0.8rem !important;
  font-size: 1.4rem !important;
}
.tabSwitchContainer {
  margin: 1.6rem 0rem !important;
}
.uploadedFileContainer {
  position: relative;
}
.uploadedFileContainer > img {
  border-radius: 0.8rem;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 43.33%,
      rgba(0, 0, 0, 0.6) 100%
    ),
    lightgray 50% / cover no-repeat;
}

.closeIconContainer {
  position: absolute;
  top: -1rem;
  left: 9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--gray-300);
  width: 2.2rem;
  height: 2.2rem;
}
.categoryContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.4rem !important;
  font-weight: 400 !important;
}
.memberContainer {
  margin-top: 1.6rem;
}
.selectText {
  color: var(--orange-500);
  font-size: 1.4rem;
  font-weight: 500;
  background-color: var(--neutral-100);
  cursor: pointer;
}
