.cardContainer {
  margin-bottom: 1.6rem;
  padding: 1.2rem 0.8rem;
  border-radius: 0.8rem;
  background: var(--neutral-100);
  width: 100%;
}
.footer {
  margin-top: 1.4rem;
}
.footerText {
  display: flex;
  align-items: center;
  color: var(--gray-500);
  font-size: 1.2rem;
  font-weight: 400;
}
.footerText > span {
  color: var(--gray-800);
  font-size: 1.2rem;
  font-weight: 400;
}
.spacing {
  margin: 0.6rem 0rem;
}
.buttonContainer {
  margin-top: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.buttonContainer > button {
  width: 30%;
}
.buttonContainer > div {
  margin-left: 1rem;
  width: 70%;
}
.headerIcon {
  width: 5.6rem;
  height: 5.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2.8rem;
  border: 0.2rem solid var(--teal-500);
  background: var(--blue-50);
}
