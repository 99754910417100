.container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: var(--neutral-100);
}

.list-container {
  height: 100%;
  overflow-y: auto;
}

.img-wrap {
  height: 100%;
  width: 100%;
  aspect-ratio: 16/9;
}
.h-100 {
  height: 100%;
}

.pdf-container {
  height: 100%;
  width: 100%;
}

.resultCounter {
  color: var(--gray-500);
  font-size: 1.5rem;
  font-weight: 500;
  text-align: left;
  padding: 0.8rem;
}

.right-container {
  height: 100%;
  width: 100%;
}

.actions-container {
  height: 10vh;
}

.cancelText {
  margin-top: 0.2rem;
  color: var(--red-600);
  font-size: 1.4rem;
  font-weight: 400;
}

.actions-item {
  height: 4.8rem;
  width: 100%;
  border-radius: 0.8rem;
  font-size: 1.4rem;
  text-transform: none;
  font-weight: 500;
}
