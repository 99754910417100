.slotNumber {
  color: var(--neutral-100);
  font-size: 2rem;
  font-weight: 500;
}
.slotContainer {
  position: relative;
  display: inline-block;
}
.slotIconContainer {
  display: block;
  width: 100%;
  height: auto;
}
.postionSlotNumber {
  position: absolute;
  top: 42%;
  left: 42%;
  transform: translate(-42%, -42%);
  text-align: center;
  z-index: 30;
}
.slotNumerAllotted {
  margin: 0.8rem 0rem;
  color: var(--gray-500);
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: capitalize;
}
.successMessage {
  color: var(--green-500);
  font-size: 1.2rem;
}
.patientNameAge {
  margin: 0.8rem 0rem 0rem 0rem;
  color: var(--gray-800);
  font-size: 1.4rem;
  font-weight: 500;
  text-transform: capitalize;
}
.slotWithTime {
  color: var(--gray-800);
  font-size: 1.4rem;
  font-weight: 500;
}
.bio {
  color: var(--gray-500);
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: capitalize;
}
.orederId {
  margin-top: 2rem;
  color: var(--gray-500);
  font-size: 1.2rem;
  font-weight: 400;
  text-transform: capitalize;
}
.testBookingGrid {
  display: flex;
  justify-content: space-between;
  padding: 1.2rem 0rem;
  border-top: 0.1rem solid var(--gray-20);
  border-bottom: 0.1rem solid var(--gray-20);
}
.leftContent {
  text-align: left;
  width: 21rem;
}
.locationBox {
  display: flex;
  margin-top: 0.2rem;
}
.maps {
  font-size: 1.2rem;
  color: var(--orange-500);
  font-weight: 500;
  cursor: pointer;
}
.dignosticName {
  color: var(--black-900);
  font-size: 1.4rem;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 2.1rem;
}
.testbookingbio {
  color: var(--gray-500);
  font-size: 1.2rem;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 1.8rem;
}
