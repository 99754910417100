.topNavigationContainer {
  padding: 1.6rem;
  border-bottom: 0.1rem solid var(--gray-50);
  background: var(--neutral-100);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0rem;
  width: 100%;
  z-index: 100;
}
.topNavigationTitleContainer {
  display: flex;
  align-items: center;
}
.topNavigationLocation {
  display: flex;
  align-items: center;
}
.topNavigationTitle {
  color: var(--black-900);
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
}
.topNavigationLocationText {
  margin-left: 0.4rem;
  color: var(--gray-500);
  font-size: 1.2rem;
  font-weight: 400;
  text-decoration-line: underline;
  cursor: pointer;
  text-decoration-skip-ink: none;
}
.topNavigationIconContainer {
  display: flex;
  align-items: center;
  height: 2.4rem;
}
.topNavigationSecondIconContainer {
  display: flex;
  align-items: center;
  margin-left: 2.4rem;
}
.cancelText {
  margin-top: 0.2rem;
  color: var(--red-600);
  font-size: 1.4rem;
  font-weight: 400;
}
.topNavigationTextContainer {
  margin-left: 0.8rem;
}
.availabilityBtnsContainer {
  display: flex;
  align-items: center;
}
.trashBtn {
  margin-right: 2.8rem;
}
.selectedScheduleContainer {
  display: flex;
  align-items: center;
}
.selectedScheduleAppointmentCount {
  margin-left: 0.8rem;
}
.selectedScheduleAppointmentCount > h4 {
  color: var(--black-900);
  font-size: 1.6rem;
  font-weight: 500;
}
.selectedScheduleAppointmentCount > p {
  color: var(--gray-400);
  font-size: 1.4rem;
  font-weight: 400;
}
.scheduleTopIconContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.request {
  display: flex;
  align-items: center;
}
.request > p {
  color: var(--orange-500);
  font-size: 1.4rem;
  font-weight: 400;
}
.pharmacyOrders {
  display: flex;
  align-items: center;
}
.verticalLine {
  margin: 0rem 1rem;
}
.redMark {
  display: flex;
  position: relative;
  bottom: 0.6rem;
  right: 0.7rem;
  color: var(--red-400);
}
.eyeIcon {
  position: relative;
}
.circleIcon {
  position: absolute;
  top: 25%;
}
