.container {
  padding: 1.2rem !important;
}
.container > h6 {
  color: var(--black-900);
  font-size: 1.6rem !important;
  font-weight: 400 !important;
}
.gridCardContainer {
  margin-top: 1.6rem !important;
  border-radius: 0.8rem;
  background: var(--neutral-100);
  overflow-y: scroll;
  max-height: 50rem;
}
.card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.1rem solid var(--gray-20);
  padding: 1.2rem 1.6rem;
}
.btnContainer {
  margin: 1.6rem 0rem !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btnFullWidth {
  width: 100%;
}
.saveBtn {
  width: 100%;
  margin-left: 1rem !important;
}
.dropdownLabel {
  color: var(--gray-800);
  font-size: 1.4rem !important;
  font-weight: 500 !important;
  text-transform: capitalize;
}
.viewBtn > button {
  padding: 0.6rem 1.6rem !important;
}
