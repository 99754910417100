.cardContainer {
  margin-bottom: 1.6rem;
  padding: 2.2rem;
  padding-inline: 4rem;
  border-radius: 0.8rem;
  background: var(--neutral-100);
  background-color: var(--neutral-100);
}

.borderLine {
  margin-top: 0.8rem;
  border-top: 0.1rem solid var(--gray-20);
}
.videoTimeDetail {
  margin-top: 0.6rem;
  display: flex;
  align-items: center;
  color: var(--gray-500);
  font-size: 1.2rem;
  font-weight: 400;
}
.openChat {
  margin: 0.8rem 0rem;
}

@media screen and (max-width: 65rem) {
  .cardContainer {
    padding: 1.2rem;
  }
}
