.diagnosticBox {
  overflow-y: auto;
  margin-top: 1.6rem;
}
.diagnosticCardGrid {
  margin-bottom: 1rem;
}
.cardDiagnostic {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: none;
  cursor: pointer;
  margin-right: 1rem;
}
.centerNameGrid {
  display: flex;
  align-items: center;
}
.centerName {
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--black-900);
}
.kmTextMobile {
  display: none;
}
.centreDetails {
  margin-left: 2.1rem;
}
.secondaryText {
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--gray-500);
}
.kmText {
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--gray-500);
  display: block;
}
.openTime {
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--green-600);
}
@media screen and (max-width: 667px) {
  .diagnosticBox {
    overflow-y: hidden;
    margin-top: 0;
  }
  .diagnosticCardGrid {
    margin-bottom: 0;
  }
  .cardDiagnostic {
    border-bottom: 0.1rem solid var(--gray-200);
    margin-right: 0;
  }
  .centerGrid {
    display: flex;
    justify-content: space-between;
  }
  .kmTextMobile {
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--gray-500);
    display: block;
  }
  .kmText {
    display: none;
  }
}
