.wrapperContainer {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  padding-bottom: 3.2rem;
}

.outerContainer {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  padding: 3.2rem 11.2rem 6.4rem 11.2rem;
  width: 100%;
}

.blogContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.8rem;
  width: 100%;
}

.contactFormContainer {
  padding: 1.6rem;
  gap: 1rem;
  background-color: var(--blue-gray-100);
  max-width: 90%;
  margin: 0 auto;
  border-radius: 1.8rem;
}

.listContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.6rem;
}

@media screen and (max-width: 65rem) {
  .blogContainer {
    grid-template-columns: repeat(1, 1fr);
    gap: 1.6rem;
  }
  .listContainer {
    padding: 0rem 1.6rem;
  }
  .wrapperContainer {
    margin: 0rem;
    padding: 0rem;
    width: 100%;
    min-height: 100vh;
    background-color: var(--neutral-100);
  }
  .headerContainer {
    border-bottom: 1px solid var(--gray-200);
    padding: 1.6rem;
  }
  .outerContainer {
    padding: 0rem;
  }
  .contactFormContainer {
    display: none;
  }
}
