.createTemplateContainer {
  display: flex;
}
.templateCount {
  padding: 0.8rem 1.2rem;
  color: var(--gray-500);
  font-size: 1.2rem;
  font-weight: 500;
  text-align: left;
}
.templateListContainer {
  width: 24.8rem;
  position: sticky;
  top: 0rem;
  height: 100%;
}
.templateNameList {
  background-color: var(--neutral-100);
  height: 90vh;
}
.templateCreateFormContainer {
  padding: 2.4rem 3.2rem;
  width: 100%;
}
.createTemplateNameActionContainer {
  background-color: var(--gray-50);
}
.createTemplateActions > h4 {
  color: var(--black-900);
  font-size: 2.4rem;
  font-weight: 500;
  text-align: left;
}
.midcontainer {
  height: 75rem;
  width: 100%;
  overflow-y: scroll;
}
.createTemplateActions,
.saveEditMakeCopyActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.saveEditMakeCopyActions {
  width: 35%;
  gap: 1.2rem;
}
.templateNameInput {
  margin: 1.6rem 0rem 2rem 0rem;
}
.diagnosisContainer > div,
.dietExerciseContainer > div {
  border-radius: 0.8rem;
}
.diagnosisContainer > div {
  margin-bottom: 0rem;
}
.makeNewTemplateBtnContainer {
  margin: 0rem 1.6rem;
  position: absolute;
  bottom: 3.6rem;
  left: 0;
  right: 0;
}
.testContainer {
  margin-top: 1.6rem;
}
.trashIcon {
  cursor: pointer;
}
.dietExerciseContainer {
  margin-top: 3rem;
}
