.cameraImageContainer {
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.retakeBtn {
  margin-right: 1rem !important;
  width: 100%;
}
.capturedImageContainer {
  margin-top: 0.8rem !important;
  flex: 1;
}
.capturedImageContainer > img {
  border-radius: 1.2rem;
  background: #d9d9d9;
  width: 100%;
  height: 87%;
}
.actionBtnContainer {
  display: flex;
  padding: 1.6rem 1.6rem 3.2rem 1.6rem !important;
  position: sticky;
  width: 100%;
  bottom: 0rem;
}
.cameraPrescriptionActionBtns {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
}
.videoInitiated > button {
  margin-top: 0.5rem;
}
