.chooseDateFilter {
  border-radius: 1.8rem;
  background: var(--neutral-100);
  padding: 0.8rem 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: min-content;
}

.chooseDateFilter > p {
  margin-left: 0.4rem;
  color: var(--orange-500);
  font-size: 1.2rem;
  font-weight: 400;
  width: 10rem;
}
.selectionContainer {
  padding: 0rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.selectText {
  color: var(--orange-500);
  font-size: 1.4rem;
  font-weight: 500;
  cursor: pointer;
}

.cancelAppointmentEvent {
  margin-left: 1.2rem;
  padding: 0.4rem 1.6rem;
  background-color: var(--neutral-100);
  border-radius: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cancelAppointmentEvent > p {
  color: var(--gray-700);
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 2rem;
  cursor: pointer;
}

@media screen and (max-width: 650px) {
  .selectionContainer {
    padding: 0rem 1.2rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .selectionContainer {
    margin-top: 0.4rem;
    width: auto;
  }
}
