.bottomNavigationContainer {
  padding: 3.2rem 11.2rem 4.8rem 11.2rem;
  border-top: 0.2rem solid var(--gray-200);
  background: var(--gray-50);
  display: flex;
  justify-content: space-between;
}
.gbscAboutContainer {
  width: 28rem;
}
.gbscAboutText > p {
  margin-top: 2.4rem;
  text-align: left;
  color: var(--gray-600);
  font-size: 1.4rem;
  font-weight: 400;
}
.linkTitle {
  margin-bottom: 1.6rem;
  color: var(--gray-600);
  font-size: 1.6rem;
  font-weight: 400;
}
.linkText {
  margin-bottom: 0.8rem;
  color: var(--gray-800);
  font-size: 1.4rem;
  font-weight: 400;
}
.downloadAppBtnContainer > button {
  padding: 0rem 1.2rem;
}
.customerSupportContainer {
  margin: 3.2rem 0rem;
}
.customerSupportContainer > p,
.socialAccountsContainer > p {
  color: var(--black-900);
  font-size: 1.4rem;
  font-weight: 500;
}
.telePhoneNumber {
  margin-top: 0.8rem;
  display: flex;
  align-items: center;
}
.telePhoneNumber > p {
  color: var(--gray-700);
  font-size: 1.4rem;
  font-weight: 500;
}
.emailContainer {
  display: flex;
  align-items: center;
}
.emailContainer > p {
  color: var(--gray-700);
  font-size: 1.4rem;
  font-weight: 500;
}
.timingContactEmailContainer > p {
  margin: 0.8rem 0rem;
  color: var(--gray-500);
  font-size: 1.2rem;
  font-weight: 400;
}
.socialLinks {
  margin-top: 1.6rem;
  display: flex;
  align-content: center;
}

.iconOuterContainer {
  margin-right: 1rem;
  border-radius: 2.4rem;
  background: var(--neutral-100);
  width: 4.8rem;
  height: 4.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
