.mobileBox,
.otpInnerContainer {
  padding: 0rem 1.6rem 0rem 1.6rem;
}
.headerContainer,
.headerBackNav,
.otpHeader {
  padding: 1.6rem 1.6rem 0rem 1.6rem;
  text-align: left;
}

.headerBackNav {
  display: flex;
  align-items: center;
}
.heading {
  color: var(--gray-800);
  font-size: 1.4rem !important;
  font-weight: 500 !important;
}
.buttonContainer {
  padding: 1.2rem 1.6rem 1.6rem 1.6rem;
  border-top: 0.1rem solid #ebebeb;
  background: var(--neutral-100);
}

.mobileVerifyErrorContainer {
  margin-bottom: 2rem;
}

.errorMessage {
  color: rgba(255, 0, 0, 0.6);
  margin-right: 2rem;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}

.errorMessage::before {
  content: "⚠ ";
}
.mobileInputContainer {
  margin: 2rem 0rem 2.8rem 0rem !important;
  display: flex;
  width: 100%;
}
.otpInputContainer {
  margin: 1.6rem 0rem 1rem 0rem !important;
  width: 100%;
}
.countryCodeInput {
  width: 21%;
  margin-right: 0.8rem !important;
}
.subHeading {
  color: var(--gray-600);
  font-size: 1.2rem !important;
  font-weight: 400;
  margin-top: 0.8rem !important;
}
.otpLabeling {
  font-size: 1.2rem !important;
  font-style: normal;
  font-weight: 400 !important;
}
.resendContainer {
  text-align: left;
}
.orangeText {
  color: var(--orange-500) !important;
  cursor: pointer;
}

.otpTermConditionText {
  color: var(--gray-700);
}
.disabledResend {
  color: var(--gray-400);
}
.termsContainer {
  margin: 1.6rem 0rem 0.8rem 0rem !important;
}
