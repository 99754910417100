.callingScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100vh;
  background-color: var(--neutral-100);
  padding: 3.2rem;
  z-index: 99999999;
  position: absolute;
  top: 0;
}

p.statusText {
  color: var(--black-900);
  font-size: 1.6rem;
  font-weight: 400;
  margin: 0rem;
}

.profileInfo {
  text-align: center;
  position: relative;
  top: -7.2rem;
  width: 100%;
}

.profileInfo > img {
  margin-top: -7.2rem;
  width: 10.8rem;
  height: 10.8rem;
  border-radius: 50%;
  border: 0.2rem solid var(--teal-500);
  margin-bottom: 1rem;
}

p.profileName {
  color: var(--black-900);
  font-size: 1.4rem;
  font-weight: 500;
  text-transform: capitalize;
}

p.profileDetails {
  color: var(--gray-600);
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: capitalize;
}

.endCallButton,
.pickCallButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4.7rem;
  width: 6.4rem;
  height: 6.4rem;
  cursor: pointer;
  border: none;
}
.endCallButton > p,
.pickCallButton > p {
  display: none;
}

.pickCallButton {
  background: var(--green-500);
}

.endCallButton {
  background: var(--red-400);
}

.callActionBtns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.ringingBox {
  display: none;
}

@media only screen and (min-width: 1024px) {
  .callingScreen {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--neutral-100);
    width: 44rem !important;
    height: 21rem;
    padding: 2rem 1.6rem;
    border-radius: 0.8rem;
    border: 0.2rem solid var(--gray-200);
    justify-content: space-between;
    z-index: 99999999;
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    top: auto;
  }

  p.statusText {
    position: absolute;
    bottom: 10rem;
    left: 10rem;
    font-size: 1.2rem;
    font-weight: 400;
    padding: 0.4rem 0.8rem;
    border-radius: 1.1rem;
    background-color: var(--gray-20);
  }

  .profileInfo {
    display: flex;
    text-align: center;
    position: relative;
    top: 0rem;
    width: 100%;
    gap: 2rem;
  }

  .profileInfo > img {
    margin-top: 0rem;
    width: 5.6rem;
    height: 5.6rem;
    margin-bottom: 1rem;
  }

  .doctorDetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  p.profileName {
    font-size: 1.6rem;
  }

  p.profileDetails {
    font-size: 1.4rem;
  }

  .endCallButton,
  .pickCallButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.8rem;
    width: 19.6rem;
    height: 4.8rem;
    cursor: pointer;
  }

  .pickCallButton {
    gap: 0.8rem;
    background: var(--orange-500);
    color: var(--neutral-100);
  }

  .endCallButton {
    background: var(--neutral-100);
    color: var(--orange-500);
    border: 0.1rem solid var(--gray-200);
  }
  .endCallButton > svg {
    display: none;
  }

  .callActionBtns {
    display: flex;
    flex-direction: row-reverse !important;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .endCallButton > p,
  .pickCallButton > p {
    display: block;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2.1rem;
  }

  .ringingBox {
    display: flex;
    gap: 0.5rem;
    margin-left: 5rem;
  }
  .ringingText {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.8rem;
    color: var(--green-500);
  }
}
