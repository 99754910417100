.container {
  padding: 0.8rem 1.2rem;
  border-radius: 0.8rem;
  border: 0.1rem solid var(--gray-200);
  color: var(--black-900);
  font-size: 1.2rem !important;
  font-weight: 400 !important;
  cursor: pointer;
}

.labelWithTrash {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.removeItem {
  display: flex;
  align-items: center;
  margin-left: 0.8rem !important;
}
