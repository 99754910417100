.consumerNameOrderDateContainer {
  display: flex;
  justify-content: space-between;
}
.consumerNameOrderDateContainer > h6 {
  color: var(--black-900);
  font-size: 1.6rem;
  font-weight: 500;
}
.consumerNameOrderDateContainer > p {
  color: var(--gray-500);
  font-size: 1.2rem;
  font-weight: 400;
}
.orderType {
  margin: 0.8rem 0rem;
  color: var(--gray-500);
  font-size: 1.4rem;
  font-weight: 500;
}
.orderId,
.address,
.ageGender {
  color: var(--gray-400);
  font-size: 1.4rem;
  font-weight: 400;
  text-transform: none;
}
div.orderCompleted > button {
  border: transparent !important;
}
.pdfContainer {
  margin-top: 0.8rem;
  display: flex;
  align-items: center;
  padding: 0.8rem 1.6rem;
  border-radius: 1.8rem;
  background: var(--gray-50);
  width: min-content;
}
.pdfContainer > p {
  margin-left: 0.4rem;
  color: var(--gray-500);
  font-size: 1.4rem;
  font-weight: 400;
}
.amount {
  color: var(--gray-500);
  font-size: 1.4rem;
  font-weight: 400;
}
.deliveryPartner {
  color: var(--gray-800);
  font-size: 1.6rem;
  font-weight: 500;
  text-transform: uppercase;
}
.deliveryDate {
  color: var(--gray-500);
  font-size: 1.2rem;
  font-weight: 400;
}
.orderRejected {
  display: flex;
  justify-content: center;
  align-items: center;
}
.paymentContainer {
  margin-top: 2rem;
}
.orderConfirmed {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.uploadReportBtnContainer {
  width: 100%;
  margin-right: 1.6rem;
}
.testUploadReportContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
