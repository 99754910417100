.categoryContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.4rem;
  font-weight: 400;
}

.healthCategoriesContainer {
  width: 100%;
}

.accordionContainer {
  background: var(--neutral-100);
  padding: 1.2rem 1rem !important;
}
