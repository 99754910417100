.container {
  padding: 1.6rem;
  border-bottom: 0.1rem solid var(--gray-200);
}
.reviewerReplyHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.reviewerReplyHeader > h6 {
  color: var(--black-900);
  font-size: 1.4rem !important;
  font-weight: 500 !important;
}
.reviewerReplyHeader > p,
.reviewerText {
  color: var(--black-900);
  font-size: 1.2rem !important;
  font-weight: 400 !important;
  text-align: justify;
}

.reviewerReplyHeader > p {
  color: var(--gray-600);
}

.reviewerText {
  margin: 1.2rem 0rem !important;
}
.reviewStarContainer {
  margin-top: 0.2rem !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.replyContainer {
  border-left: 0.2rem solid var(--gray-200);
  margin-left: 1.2rem !important;
  padding-left: 1.2rem !important;
}
.repliedMessageContainer {
  color: var(--black-900);
  font-size: 1.2rem !important;
  font-weight: 400 !important;
  text-align: justify;
}

.replayToReview {
  color: var(--orange-500);
  font-size: 1.4rem !important;
  font-weight: 500 !important;
  text-transform: capitalize;
}

@media (min-width: 900px) {
  .container {
    margin-bottom: 0.8rem !important;
    border-radius: 1.2rem !important;
    border-bottom: 0.1rem solid var(--gray-200);
    background: var(--neutral-100);
  }
}
