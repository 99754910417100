.tagNameContainer {
  min-width: 9.5rem;
  border-radius: 1.2rem;
  background: var(--cream-500);
  padding: 0.2rem 0.8rem;
  color: var(--black-900);
  font-size: 1.2rem !important;
  font-weight: 400 !important;
  margin: 0.4rem !important;
}
