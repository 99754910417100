.clinicImage {
  object-fit: cover;
  height: 21rem;
  width: 100%;
  background-size: cover;
}
.backNavigation {
  position: absolute;
  top: 0.25%;
  left: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.6rem;
  background: var(--neutral-100);
  padding: 0.8rem;
  width: 3.2rem;
  height: 3.2rem;
  z-index: 100;
  cursor: pointer;
}
.container {
  text-align: center;
  background-color: var(--neutral-100);
  width: 100%;
}

.doctorImage {
  border-radius: 50%;
  outline: 0.2rem solid var(--cream-500);
  width: 14rem;
  height: 14rem;
  object-fit: cover;
}
.doctorProfileStatus {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.doctorDetailContainer {
  margin-bottom: 0.2rem;
  padding: 0rem 1.6rem;
}
.doctorInfoContainer > h4 {
  color: var(--black-900);
  font-size: 1.6rem;
  font-weight: 500;
}
.doctorInfoContainer > h6 {
  color: var(--gray-500);
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.8rem;
}
.doctorInfoContainer > p {
  color: var(--gray-500);
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.8rem;
}

.doctorAboutWrapper {
  interpolate-size: allow-keywords;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  position: relative;
}

.bottomGradient {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1rem;
  background: linear-gradient(to bottom, transparent, var(--neutral-400));
}

.viewMoreContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0rem 1rem 0rem;
  border-bottom: 0.1rem solid var(--gray-200);
  padding-bottom: 1rem;
  width: 100%;
}
.totalClinicCount,
.viewMoreContainer > p {
  color: var(--orange-500);
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
  line-height: 1.8rem;
}

.totalClinicCount {
  margin: 0.4rem 0rem 1rem 0rem;
}

.doctorAbout {
  padding: 0.9rem 0rem 0rem 0rem;
  border-top: 0.1rem solid var(--gray-200);
  color: var(--gray-500);
  font-size: 1.2rem;
  font-weight: 400;
}
.tagsContainer {
  margin: 2rem 0rem 1.2rem 0rem;
}
.tagsContainer > p {
  color: var(--black-900);
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.tagItemsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.reviewCountHeader {
  display: flex;
  justify-content: center;
  align-items: center;
}
.reviewCountHeader > p {
  color: var(--black-900);
  font-size: 1.4rem;
  font-weight: 500;
}
.expertiseContainer {
  margin: 1.2rem 0rem 1.6rem 0rem;
}
.expertiseContainer > p {
  color: var(--gray-500);
  font-size: 1.2rem;
  font-weight: 400;
  width: 80%;
  margin: auto;
}

.videoReviewContainer {
  padding: 1.6rem;
  background: var(--neutral-100);
}
.videoReviewHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.videoReviewHeader > h6 {
  color: var(--black-900);
  font-size: 1.4rem;
  font-weight: 500;
}
.videoReviewHeader > p {
  color: var(--orange-500);
  font-size: 1.2rem;
  font-weight: 500;
}
.videoReviewThumbnail {
  margin-top: 1.2rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
}
.clinicInfoWrapper {
  padding: 1.2rem 0rem 1.6rem 0rem;
  border-bottom: 0.1rem solid var(--gray-20);
}
.clinicDetailContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.clinicName {
  color: var(--gray-500);
  font-size: 1.4rem;
  font-weight: 500;
  text-transform: capitalize;
}
.priceBranch {
  margin-top: 0.4rem;
  color: var(--gray-500);
  font-size: 1.2rem;
  font-weight: 400;
}
.directionIcon {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
}
.viewMoreClinicContainer {
  padding: 1.6rem;
  text-align: left;
}
.viewMoreClinicContainer > h4 {
  margin-bottom: 0.8rem;
  color: var(--black-900);
  text-align: center;
  font-size: 1.4rem;
  font-weight: 500;
}
/* Styles for large desktops */
@media (min-width: 1025px) {
  .container {
    width: 100%;
  }
}

.tabInnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tabInnerContainer > p {
  margin-left: 0.8rem !important;
  font-size: 1.4rem !important;
}
.tabBtnContainer {
  width: 100%;

  border-radius: 0.8rem;
  border: 0.1rem solid var(--gray-200);
}
