.container {
  padding: 1.6rem 1.6rem 0rem 1.6rem;
}
.patientDetail {
  margin-bottom: 0.8rem !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.admittedDateDuration {
  color: var(--gray-400);
  font-size: 1.4rem !important;
  font-weight: 400 !important;
  display: flex;
  align-items: center;
}
.roomNo {
  border-radius: 1.5rem;
  background: var(--gray-50);
  padding: 0.4rem 0.8rem;
  color: var(--gray-500);
  font-size: 1.2rem !important;
  font-weight: 400 !important;
  width: max-content;
}
.caseHistory {
  margin: 0.8rem 0rem !important;
}
.caseHistory > p,
.admitPatientNo {
  color: var(--gray-400);
  font-size: 1.4rem !important;
  font-weight: 400 !important;
}
.clinicTeamTitle {
  margin: 2.2rem 0rem !important;
}
.clinicTeams {
  margin-bottom: 2rem !important;
}
.btnsContainer > button {
  margin-bottom: 1.6rem !important;
}
.sponsorNameAdmitPatientNo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sponsorName {
  color: var(--gray-700);
  font-size: 1.4rem !important;
  font-weight: 400 !important;
}
.actionBtns {
  margin-top: 1.4rem !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.actionBtns > button {
  margin-right: 1rem !important;
}
