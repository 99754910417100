.container {
  margin-bottom: 0rem;
  height: 40rem;
}

.doctorProfileHeaderContainer {
  padding: 1.6rem;
  background-color: var(--neutral-100);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.doctorImageContainer {
  margin-right: 1.6rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.doctorImageContainer > img {
  border-radius: 14.35rem;
  border: 0.2rem solid var(--teal-500);
  width: 12rem;
  height: 12rem;
  object-fit: cover;
}

.doctorProfileDetailContainer {
  flex: 1;
}

.doctorProfileDetailContainer > h4 {
  margin-bottom: 0.2rem;
  color: var(--blue-500);
  font-size: 1.6rem;
  font-weight: 600;
}

.doctorProfileDetailContainer > h5 {
  margin-bottom: 0.6rem;
  color: var(--black-900);
  font-size: 1.4rem;
  font-weight: 500;
}

.doctorProfileDetailContainer > h6 {
  margin-bottom: 0.2rem;
  color: var(--gray-400);
  font-size: 1.4rem;
  font-weight: 400;
}

.doctorExp {
  margin-bottom: 0.6rem;
  color: var(--gray-700);
  font-size: 1.4rem;
  font-weight: 400;
}

.doctorProfileView {
  color: var(--orange-500);
  font-size: 1.4rem;
  font-weight: 500;
  cursor: pointer;
}

.patientReviewsContainer {
  margin-bottom: 6.6rem;
}

.reviewRatingCountContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.3rem;
}
.webratingValue {
  margin-left: 1rem;
}
.ratingCount {
  display: flex;
  align-items: center;
  color: var(--blue-500);
  font-size: 1.6rem;
  font-weight: 600;
}
.webratingCount {
  display: flex;
  align-items: center;
  color: var(--black-900);
  font-size: 3rem;
  font-weight: 500;
}
.iconContainer {
  display: inline-flex;
}
.reviewCount {
  color: var(--blue-500);
  font-size: 1.6rem;
  font-weight: 400;
}
.webreviewCount {
  margin-left: 0.2rem;
  color: var(--gray-500);
  font-size: 2rem;
  font-weight: 400;
}

.ratingFilterContainer {
  padding-left: 1.6rem;
  padding-bottom: 1rem;
}
.reviewRatingSearchContainer {
  padding: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.searchReviewIcon {
  display: flex;
  justify-content: end;
  padding: 0rem 1rem;
  cursor: pointer;
}
.searchReviewInput {
  width: 100%;
}
.searchReviewInput > input {
  height: 3rem;
  background-color: var(--gray-50);
  padding-left: 1rem;
}
.RatingFilters {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: nowrap;
  padding: 1rem 1rem;
}
.chooseDateFilter {
  border-radius: 1.8rem;
  background: var(--neutral-100);
  padding: 0.8rem 1.2rem;
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: fit-content;
  cursor: pointer;
}

.chooseDateFilter p {
  font-size: 1.2rem;
  white-space: nowrap;
  margin: 0;
  color: var(--white);
}

.chooseDateFilter p[data-custom="true"] {
  color: var(--orange-500);
}
.chipFilter {
  overflow: hidden;
}
.dateRangeSelectorContainer {
  margin-top: 2.625rem;
}
/* Styles for large desktops */
@media (min-width: 900px) {
  .patientReviewsContainer {
    margin: 2.8rem;
    background-color: transparent;
    border-radius: 1rem;
  }
  .ratingFilterContainer {
    text-align: left;
  }
  .ratingCount > span {
    display: none;
  }
  .reviewRatingCountContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-right: 1rem;
  }
  .reviewFilterContainer,
  .searchReviewInput > input {
    background-color: var(--blue-gray-100);
  }
}
.BoldDot {
  font-weight: 1000;
  display: inline-flex;
  margin: 0rem 0.5rem;
}
.profileHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
