.nextVideoCard {
  display: flex;
  gap: 0.8rem;
  align-items: flex-start;
  cursor: pointer;
}

.videoThumbnail {
  width: 16rem;
  height: 9rem;
  border-radius: 0.8rem;
  object-fit: cover;
}

.videoContentContainer {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  height: 100%;
}

.videoTitle {
  font-weight: 500;
  font-size: 1.2rem;
  color: var(--black-900);
  line-height: 1.8rem;
}

.videoDescription {
  font-weight: 400;
  font-size: 1rem;
  color: var(--gray-700);
  line-height: 1.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.viewsAndLikesContainer {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.viewsCount,
.likesCount {
  font-weight: 400;
  font-size: 1rem;
  color: var(--gray-800);
  line-height: 1.5rem;
}
