.sliderContainer {
  position: relative;
  max-width: 100%;
}

.arrowButton {
  min-width: 4rem !important;
  width: 4rem !important;
  height: 4rem !important;
  position: absolute !important;
  top: 40%;
  transform: translateY(-40%);
  background-color: white !important;
  box-shadow: 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.1) !important;
  z-index: 20;
}

.arrowLeftButton {
  composes: arrowButton;
  left: 1rem;
}

.arrowRightButton {
  composes: arrowButton;
  right: 1rem;
}

.arrowRightButtonModal {
  right: -1rem;
}

.arrowLeftButtonModal {
  left: -1rem;
}

.innerContainer {
  display: flex;
  padding: 2rem 1rem 2rem 0rem;
  gap: 2rem;
  overflow-x: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  max-width: 100%;
}

.sectionInnerContainer {
  padding-bottom: 0rem;
}

.innerContainer::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 65rem) {
  .innerContainer {
    padding: 1rem 0rem 0rem 0rem;
  }

  .arrowButton {
    display: none;
    pointer-events: none;
  }
}
