.container {
  padding: 1.6rem !important;
  height: 45.3rem;
  width: 100%;
  overflow-y: scroll;
  background-color: white;
}
.container > h6 {
  color: var(--black-900);
  font-size: 1.4rem !important;
  font-weight: 500 !important;
  margin-bottom: 1.6rem !important;
}
.btnsContainer {
  padding: 1rem 0rem;
  background-color: white;
  bottom: 0rem;
}
.changeSaveBtn {
  margin-top: 1rem !important;
}
div.memberDetailContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.2rem;
  margin-bottom: 5rem;
}

.addMemberContainer {
  border-radius: 0.8rem;
  background: var(--warm-neutral-50);
  max-width: 9rem;
  height: 10.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.addMemberContainer > p {
  color: var(--orange-500);
  text-align: center;
  font-size: 1.2rem !important;
  font-weight: 500 !important;
}
@media screen and (max-width: 650px) {
  .container {
    background-color: transparent;
    width: 100%;
  }
}
