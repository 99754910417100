.medicineTestInputSuggestionContainer {
  border-radius: 0.8rem;
  border-bottom: 0.1rem solid var(--gray-20);
  background: var(--blue-gray-100);
  padding: 1.6rem 1.6rem 1.2rem 1.6rem;
}
.medicineTestBtnContainer {
  text-align: left;
}
.medicineTestBtnContainer > button {
  width: 24.8rem;
}
.medicineTestContainer {
  margin-top: 2.4rem !important;
}
