.boxContainer {
  padding: 1.2rem 1.6rem;
  border-bottom: 0.1rem solid var(--gray-20);
  background: var(--neutral-100);
}
div.spacing {
  margin: 0.8rem 0rem;
}
.logoutAction {
  display: flex;
  align-items: center;
}
.logoutAction > p,
p.languageHeading {
  color: var(--black-900);
  font-size: 1.4rem;
  font-weight: 500;
  cursor: pointer;
}

.selectedLanguageContainer {
  margin-top: 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
p.selectedLanguage {
  color: var(--gray-900);
  font-size: 1.4rem;
  font-weight: 400;
}
p.languageChangeHandle {
  color: var(--orange-500);
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
}
.spacingTop {
  margin-top: 0.8rem;
}
