.outerContainer {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.relatedBlogsContainer {
  background-color: var(--neutral-100);
  padding-top: 3.2rem;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
