.status {
  padding: 0.2rem 0.8rem;
  border-radius: 1.2rem;
  font-size: 1rem !important;
  font-weight: 500;
  text-align: center;
}
.statusVerified {
  background: var(--cream-500);
  color: var(--black-900);
}
.statusNew {
  background: var(--green-50);
  color: var(--black-900);
}
.statusAskFree {
  background: #457884;
  color: var(--neutral-100);
}
