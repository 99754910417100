.status {
  margin-top: 0.8rem;
  padding: 0.4rem 0.8rem;
  border-radius: 1.1rem;
  font-size: 1.2rem;
  font-weight: 400;
  width: max-content;
  text-transform: capitalize;
}
.statusUpcoming {
  background: #fff1e0;
  color: #262626;
}
.statusCompleted {
  background: #e2ffe9;
  color: #019c36;
}
.statusCancelled {
  background: #ffefef;
  color: var(--red-400);
}
.headerContainer {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
}
.imageContainer > img {
  border-radius: 50%;
  outline: 0.2rem solid var(--cream-500);
}
.profileStatus {
  position: relative;
  top: -1.4rem;
}
.name {
  color: var(--black-900);
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
  text-transform: capitalize;
}
.bio {
  color: var(--gray-500);
  font-size: 1.4rem;
  font-weight: 500;
  text-transform: capitalize;
}
.languages {
  color: var(--gray-500);
  font-size: 1.4rem;
  font-weight: 400;
  text-transform: capitalize;
}
