.btnContainer {
  position: sticky;
  bottom: 0rem;
  z-index: 100;
  border-top: 0.1rem solid var(--gray-200);
  background: var(--blue-gray-100);
  padding: 1.6rem 2.4rem;
  width: 100%;
  display: flex;
  justify-content: end;
}
.btnContainer > div {
  margin-right: 1rem;
}
.btn button {
  padding: 0rem 1.6rem;
  margin-right: 1rem;
}

.createInnerContainer {
  width: 100%;
  padding: 1.6rem;
  margin-bottom: 1.6rem;
}
.title {
  color: var(--black-900);
  font-size: 1.6rem;
  font-weight: 400;
}
.selectTitle {
  margin-top: 0.4rem;
  margin-bottom: 1.2rem;
  color: var(--gray-400);
  font-size: 1.4rem;
  font-weight: 400;
}
.createAvailInputs {
  display: flex;
  align-content: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.6rem;
  gap: 1.6rem;
  margin-top: 1rem;
}
.selectText {
  color: var(--orange-500);
  font-size: 1.4rem;
  font-weight: 500;
  background-color: var(--neutral-100);
  cursor: pointer;
}
.deleteAfterContainer {
  margin-top: 2.8rem;
  text-align: left;
}
.deleteAfterSelectionContainer {
  margin-top: 1.2rem;
}

.btnContainer > button {
  width: auto;
  padding: 0rem 4rem;
}

.dateContainer {
  margin: 1.2rem 0rem;
}
@media screen and (max-width: 1024px) {
  .createInnerContainer {
    padding: 1.6rem;
    width: 100%;
    padding-left: 1rem;
    padding-bottom: unset;
  }
  .btn {
    margin-right: 1rem;
  }
  .btnContainer > button {
    width: 100%;
  }
  .btnContainer {
    bottom: 0rem;
    z-index: 100;
    border-top: 0.1rem solid var(--gray-200);
    background: var(--blue-gray-100);
    padding: 1.2rem 1.6rem 1.2rem 1.6rem;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
}
@media screen and (max-width: 768px) {
  .createInnerContainer {
    padding: 1.6rem;
    margin: 0rem 0rem 7.5rem 0rem;
    width: 100%;
  }
  .btnContainer {
    position: fixed;
  }
}
