.container {
  padding: 1.6rem;
}
.header {
  display: flex;
  align-items: center;
}
.header > p {
  margin-left: 1rem !important;
  color: var(--black-900);
  font-size: 1.6rem !important;
  font-weight: 400 !important;
}

.icuWardContainer {
  margin: 1.6rem 0rem !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.icuWardContainer > div {
  width: 100%;
}
.changeBed {
  margin-left: 2.4rem !important;
}

.name {
  color: var(--gray-700);
  font-size: 1.6rem !important;
  font-weight: 600 !important;
}

.admittedDateDuration {
  color: var(--gray-400);
  font-size: 1.4rem !important;
  font-weight: 400 !important;
  display: flex;
  align-items: center;
}
.patientDetail {
  margin-top: 1.8rem !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
