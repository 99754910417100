.container {
  background-color: var(--neutral-100);
  height: 100%;
}

.paymentContainer > h4 {
  color: var(--black-900);
  font-size: 1.4rem;
  font-weight: 500;
}

.paymentDetails,
.totalPaymentDetails {
  margin-top: 1.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.paymentDetails > p,
.totalPaymentDetails > p {
  color: var(--black-900);
  font-size: 1.2rem;
  font-weight: 400;
  text-transform: capitalize;
}

.paymentDetails > h5,
.totalPaymentDetails > h5 {
  color: var(--black-900);
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 99.538%;
}

.line {
  margin-top: 1rem;
}
