.container {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.settingIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.6rem;
  background: var(--neutral-100);
  width: 14.8rem;
  height: 14.8rem;
}
.mainTitle {
  margin: 1.6rem 0rem 0.8rem 0rem !important;
  color: var(--black-900);
  text-align: center;
  font-size: 1.6rem !important;
  font-weight: 400;
}
.footerText {
  color: var(--gray-400);
  text-align: center;
  font-size: 1.4rem !important;
  font-weight: 400;
}
