.pharmacyProfileContainer {
  padding: 1rem 1.6rem;
  text-align: left;
}
.pharmacyMonthlyAnalyticsContainer {
  padding: 2.4rem 1.6rem;
  text-align: left;
}
.pharmacyMonthlyAnalyticsContainer {
  background: var(--neutral-100);
  border-radius: 1.6rem;
}
.pharmacyProfileContainer > img {
  width: 8.4rem;
  height: 8.4rem;
  border-radius: 50%;
}
.pharmacyProfileDetails {
  margin-top: 2rem;
}
.pharmacyProfileDetails > p {
  margin: 0.8rem 0rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pharmacyTimings {
  display: flex;
}
.pharmacyTimingsDetails {
  margin-left: 0.8rem;
}
.pharmacyProfileDetails > h6 {
  color: var(--black-900);
  font-size: 1.6rem;
  font-weight: 600;
}
.pharmacyDetail {
  color: var(--gray-600);
  font-size: 1.4rem;
  font-weight: 400;
}
.chooseDateFilter {
  margin-left: 1rem;
  border-radius: 1.8rem;
  background: var(--neutral-100);
  padding: 0.8rem 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: min-content;
}

.chooseDateFilter > p {
  margin-left: 0.4rem;
  color: var(--orange-500);
  font-size: 1.2rem;
  font-weight: 400;
  width: 10rem;
  width: max-content;
}
.analayticsHeader {
  display: flex;
  justify-content: space-between;
}

.export {
  padding: 0.2rem;
  border-radius: 0.3rem;
  background: var(--gray-50);
  height: min-content;
}

.export > p {
  color: var(--gray-800);
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
}
.title > h6 {
  color: var(--gray-900);
  font-size: 1.4rem;
  font-weight: 500;
}
.title > p {
  margin-top: 0.2rem;
  color: var(--gray-600);
  font-size: 1.2rem;
  font-weight: 500;
}
.analayticsDetails {
  margin-top: 1.8rem;
}
.metrics {
  margin: 1.2rem 0rem;
  padding-bottom: 1.2rem;
  border-bottom: 0.1rem solid var(--gray-300);
}
.metrics:last-child {
  border: none;
}
.metrics > h6 {
  color: var(--gray-500);
  font-size: 1rem;
  font-weight: 400;
}
.metrics > p {
  margin-top: 0.2rem;
  color: var(--gray-700);
  font-size: 1.4rem;
  font-weight: 500;
}

.isMobileContainer {
  background: var(--neutral-100);
}
.filterContainer {
  background: var(--gray-50);
  padding: 1.4rem;
}
.BoldDot {
  font-weight: bold;
  font-size: 1.2em;
  line-height: 1;
  vertical-align: middle;
  margin: 0rem 0.5rem;
}

.pharmacyDiagnosticHeaderContainer {
  display: flex;
  justify-content: space-between;
}
.pharmacyDiagnosticHeaderContainer > img {
  width: 8.4rem;
  height: 8.4rem;
  border-radius: 50%;
}
.pharmacyDiganosticProfileHeader {
  display: flex;
  justify-content: flex-end;
}
