.container {
  padding: 4.8rem 11.2rem 0rem 11.2rem;
  background: linear-gradient(
    245deg,
    var(--neutral-100) 41.78%,
    var(--cream-100) 81.88%
  );
  width: 100%;
  display: flex;
  justify-content: space-around !important;
  align-items: center;
}

.mobileImageContainer > img {
  width: 34.4rem;
  height: 47.3511rem;
}
.textContainer {
  width: 59.2rem;
}
.downloadAppNowTitle > h4 {
  color: var(--black-900);
  font-size: 3.6rem !important;
  font-weight: 500 !important;
}
.appDescription {
  margin: 2.4rem 0rem !important;
  color: var(--gray-600);
  font-size: 1.4rem !important;
  font-weight: 400 !important;
}
.getAppDownloadLink {
  display: flex;
  margin-bottom: 2.4rem !important;
  width: 100%;
}
.getAppDownloadLink > div > div {
  width: 38rem !important;
}
.getAppDownloadLink > div > div > input {
  border-radius: 0.8rem;
  outline: 0.1rem solid var(--gray-200) !important;
  background: var(--neutral-100);
}
.getAppDownloadLink > button {
  width: 20rem;
  margin-left: 1.6rem !important;
  padding: 2.9rem 0rem !important;
}
.downloadBtnText {
  display: flex;
  flex-direction: column !important;
  align-items: flex-start;
  margin-left: 0.8rem !important;
}
.downloadTextHeader {
  color: var(--neutral-100);
  font-size: 1.2rem !important;
  font-weight: 400 !important;
}
.downloadTextFooter {
  color: var(--neutral-100);
  font-size: 1.6rem !important;
  font-weight: 500 !important;
}
.downloadBtnContainer {
  display: flex;
}
.downloadBtnContainer > button,
.downloadBtnContainer > div > button {
  padding: 0.8rem 0rem !important;
  width: 22.5rem;
  border-radius: 0.8rem;
  background: var(--black-900) !important;
  border: 0.1rem solid var(--black-900) !important;
}
.playStoreIconContainer {
  margin-right: 2.4rem !important;
}

@media screen and (max-width: 65rem) {
  .container {
    padding: 4.8rem 0rem;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem !important;
  }

  .mobileImageContainer > img {
    width: 19.4rem;
    height: 25.3511rem;
  }
  .textContainer {
    width: auto;
  }
  .downloadAppNowTitle > h4 {
    font-size: 2.6rem !important;
    text-align: center;
  }

  .getAppDownloadLink {
    display: flex;
    gap: 2rem;
    padding: 1rem;
    margin-bottom: 1.4rem !important;
    width: 100%;
  }
  .getAppDownloadLink > div > div {
    width: auto !important;
    margin-right: 0;
  }

  .getAppDownloadLink > div > div > input {
    border-radius: 0.8rem;
    outline: 0.1rem solid var(--gray-200) !important;
    background: var(--neutral-100);
  }
  .getAppDownloadLink > button {
    width: fit-content;
    height: fit-content;
    margin-left: 0 !important;
    padding: 1rem !important;
    padding-block: 2rem;
    line-height: 1.6rem;
  }
  .downloadBtnContainer {
    display: flex;
    padding: 1rem;
    justify-content: space-evenly;
  }
  .downloadBtnText {
    display: flex;
    flex-direction: column !important;
    align-items: flex-start;
    margin-left: 0.8rem !important;
  }
  .downloadTextHeader {
    color: var(--neutral-100);
    font-family: Poppins;
    font-size: 1.2rem !important;
    font-weight: 400 !important;
  }
  .downloadTextFooter {
    color: var(--neutral-100);
    font-family: Poppins;
    font-size: 1.4rem !important;
    font-weight: 500 !important;
  }
  .downloadBtnContainer > button,
  .downloadBtnContainer > div > button {
    width: fit-content;
    padding: 1rem !important;
  }
  .playStoreIconContainer {
    margin-right: 0 !important;
  }
  .appDescription {
    padding: 1rem;
    text-align: center;
  }
}
