.container {
  text-align: left;
  background-color: white;
  height: 63.8rem;
  width: 50rem;
}
.doctorDetailContainer {
  height: 8.8rem;
  margin: 0.8rem 0rem;
  padding: 1.2rem;
  background: var(--neutral-100);
}
.consultationDetailContainer {
  padding-block: 1.6rem;
  background: var(--neutral-100);
}
.patientPayableContainer {
  margin-top: 0.8rem;
  padding: 1.2rem 0;
}
.consultationDetailContainer > p,
.patientPayableContainer > p,
.totalPayableContainer > p {
  color: var(--black-900);
  font-size: 1.4rem;
  font-weight: 500;
}
.innerContainer {
  margin-top: 1.8rem;
}
.consultationDetailItem {
  display: flex;
  align-items: center;
}
.consultationDetailItem > p {
  color: var(--gray-500);
  font-size: 1.4rem;
  font-weight: 400;
}
.buttonContainer {
  position: absolute;
  bottom: 1.5rem;
  width: 100%;
  padding: 1.2rem 1.6rem 1.2rem 1.6rem;
  border-top: 0.1rem solid #ebebeb;
  background: var(--neutral-100);
}
.patientPayableContainerSelected {
  background: var(--peach-300);
  margin-top: 0.8rem;
  padding: 1.2rem 0;
}

.patientDetail {
  margin-top: 1.2rem;
  display: flex;
  justify-content: space-between;
}
.patientName {
  color: var(--gray-900);
  font-size: 1.4rem;
  font-weight: 400;
  text-transform: capitalize;
}
.changePatient {
  color: var(--orange-500);
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
}
.totalPayableContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.paymentModeSelection {
  margin-top: 1.2rem;
}
.paymentModeLabel {
  color: var(--black-900);
  font-size: 1.4rem;
  font-weight: 400;
  text-transform: capitalize;
}

@media screen and (max-width: 650px) {
  .container {
    background-color: transparent;
    height: 100%;
    width: 100%;
  }
}
