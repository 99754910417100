.testCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  box-shadow: none;
  border-bottom: 1px solid var(--gray-200);
  background: var(--neutral-100);
}
.testActive {
  background: var(--warm-neutral-50);
}
.testName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 20rem;
  font-size: 1.4rem;
  font-weight: 400;
}
.priceText {
  font-size: 1.2rem;
  font-weight: 400;
  color: var(--gray-10);
}
.addButton {
  color: var(--orange-500);
  cursor: pointer;
}
.noFound {
  text-align: center;
}
