.outerContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  cursor: pointer;
  max-width: min-content;
}

.thumbnail {
  width: 27.4rem;
  height: 16.4rem;
  border-radius: 0.8rem;
  object-fit: cover;
}

.title {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2.1rem;
  text-align: left;
  font-family: "Poppins", sans-serif;
}

.titleContainer {
  display: flex;
  flex-direction: column;
}

.readCount {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.1rem;
  text-align: left;
  color: var(--neutral-800);
  font-family: "Poppins", sans-serif;
}

@media screen and (max-width: 65rem) {
  .thumbnail {
    width: 20rem;
    height: 12rem;
  }

  .title,
  .readCount {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
}
