.container {
  margin-top: 1.2rem;
}
.pdfContainer {
  margin-top: 0.8rem;
  display: flex;
  align-items: center;
  padding: 0.8rem 1.6rem;
  border-radius: 1.8rem;
  background: var(--gray-50);
  width: min-content;
}
.pdfContainer > p {
  margin-left: 0.4rem;
  color: var(--gray-500);
  font-size: 1.4rem;
  font-weight: 400;
}
.title {
  color: var(--black-900);
  font-size: 1.4rem;
  font-weight: 500;
}
.delete {
  margin-left: 0.8rem;
}
.testReportContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
