.container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
div.name {
  color: var(--gray-400);
  text-align: center;
  font-size: 1.2rem;
  font-weight: 400;
}
div.card {
  width: 7.6rem;
  height: 8.6rem;
  border-radius: 0.8rem;
  border: 0.1rem solid var(--gray-50);
  background-color: var(--gray-50);
  padding: 1.2rem 0rem 0.8rem 0rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
div.selected {
  border-radius: 0.8rem;
  border: 0.1rem solid var(--orange-500);
  position: relative;
}
.removeSelection {
  border-radius: 1.2rem;
  background: var(--gray-300);
  padding: 0.4rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -1rem;
  top: -1rem;
  z-index: 100;
}
