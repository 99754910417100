.modalBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 40rem;
  background: var(--neutral-100);
  box-shadow: none;
  padding: 3.2rem;
  border-radius: 0.8rem;
  text-align: center;
}
.modalTitle {
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--orange-500);
  margin-bottom: 1rem;
}
.modalDes {
  font-size: 1.2rem;
  font-weight: 400;
  color: var(--gray-500);
  margin-bottom: 2rem;
}

@media screen and (max-width: 667px) {
  .modalBox {
    max-width: 32rem;
  }
}
