.createAction {
  border-radius: 50%;
  cursor: pointer;
  background: var(--gray-900);
  box-shadow: 0.2rem 0.4rem 0.4rem 0rem rgba(0, 0, 0, 0.25);
  width: 6rem;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 8.2rem;
  right: 1.6rem;
  z-index: 1000;
}
