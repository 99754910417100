.container {
  padding: 1.2rem 11.2rem;
  text-align: center;
  width: 100%;
}
.container > p {
  color: var(--gray-500);
  font-family: "Plus Jakarta Sans";
  font-size: 1.2rem;
  font-weight: 400;
}
