.text-gray {
  color: var(--gray-400);
  font-size: 1.2rem;
  font-weight: 400;
  text-transform: capitalize;
}

.orderStatus {
  font-size: 1.2rem;
  font-weight: 400;
}

.orderStatus.success {
  background: var(--green-50);
  color: var(--green-500);
}

.orderStatus.rejected {
  background: var(--red-100);
  color: var(--red-900);
}

.orderType {
  margin-top: 0.5rem;
  color: var(--gray-400);
  font-size: 1.2rem;
  font-weight: 400;
}

.card {
  height: 100%;
  width: 100%;
  padding: 0.8rem 1.6rem;
  background: var(--neutral-100);
  text-transform: none;
  cursor: pointer;
  color: inherit;
  text-align: left;
  border-bottom: 0.1rem solid var(--gray-20);
}

.card.active {
  background-color: var(--warm-neutral-50);
}

.consumerName {
  color: var(--black-900);
  font-size: 1.4rem;
  font-weight: 500;
}

.pdfContainer > p {
  margin-left: 0.4rem;
  color: var(--gray-500);
  font-size: 1.4rem;
  font-weight: 400;
}
.testReportContainer {
  display: flex;
  flex-wrap: wrap;
}
