.container {
  padding: 2.4rem 1.6rem;
  text-align: left;
}
.admitPatientTitle {
  margin-bottom: 1.6rem;
  color: var(--black-900);
  font-size: 2.4rem;
  font-weight: 500;
}
.inputSpacing {
  margin: 1.6rem 0rem;
}
.admitbtnContainer {
  display: flex;
  width: 100%;
}

.ageGenderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ageInput {
  width: 100%;
}
.genderSelector {
  width: 100%;
  margin-left: 1.6rem;
}
.btn {
  margin-right: 1rem;
}
.btnContainer > button,
.btn {
  width: auto;
  display: flex;
}
.btnContainer > button {
  padding: initial;
}
.admitBtnContainer {
  display: flex;
  gap: 1rem;
}
.admitBtnContainer > button {
  width: max-content;
}
.btnContainer {
  border-top: 0.1rem solid var(--gray-200);
  background: var(--gray-50);
  padding: 1.2rem 1.6rem 1.2rem 1.6rem;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.wardIcuRooms {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.spaceBySideItem {
  margin-right: 2.4rem;
  width: 100%;
}
.bedNumber {
  width: 100%;
}
.sideByside {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.sideByside > .inputSpacing {
  width: 100%;
}
@media screen and (max-width: 650px) {
  .container {
    width: 100%;
    margin-bottom: 9rem;
  }
  .btnContainer {
    padding: 1.2rem 1.6rem 3.6rem 1.6rem;
    position: fixed;
    bottom: 0rem;
    z-index: 100;
    justify-content: center;
  }
  .btnContainer > button {
    width: 100%;
  }
}
