.patientDetailContainer {
  display: flex;
  justify-content: space-between;
  padding: 1.2rem !important;
  border-bottom: 0.1rem solid var(--gray-20);
  background-color: var(--neutral-100);
  cursor: pointer;
  border-radius: 0.8rem;
}
.patientContainer {
  display: flex;
  align-items: flex-start;
  width: 100%;
}
.patientNameContainer {
  margin-left: 0.8rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.active {
  background-color: var(--warm-neutral-50);
}
.status {
  padding: 0.2rem 0.6rem;
  border-radius: 1.1rem;
  font-size: 1.2rem !important;
  font-weight: 400;
}

.slotRecordContainer {
  margin-top: 0.4rem !important;
  display: flex;
}
.slotRecordNumber {
  margin-right: 0.6rem !important;
  color: var(--gray-400);
  font-size: 1.2rem !important;
  font-weight: 400 !important;
}
.slotChip {
  width: max-content;
  border-radius: 1.5rem;
  padding: 0rem 0.8rem;
  background: var(--gray-20);
}
.clinicRecordNumber {
  margin-right: 0.8rem ;
  color: var(--gray-400);
  font-size: 1.2rem ;
  font-weight: 400 ;
}
.videoIconContainer {
  width: 3.6rem;
  height: 3.6rem;
  padding: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.8rem;
  background: var(--orange-500);
  cursor: pointer;
}

.statusText {
  font-size: 1.2rem !important;
  font-weight: 400 !important;
  text-transform: capitalize;
  cursor: pointer;
}
.statusUpcoming {
  background: var(--green-50);
  color: var(--green-400);
}
.statusCompleted {
  background: var(--green-50);
  color: var(--green-500);
}
.statusCancelled {
  background: var(--red-50);
  color: var(--red-400);
}

.messageUnreadContainer {
  display: flex;
  align-items: center;
  gap: 0.1rem;
}
.messageUnreadTime {
  color: var(--orange-500);
  text-align: right;
  font-size: 1.2rem !important;
  font-weight: 400 !important;
  line-height: 99.538%;
}
.messageUnreadCount {
  margin-left: 0.4rem !important;
  border-radius: 1rem;
  background: var(--orange-500);
  color: var(--neutral-100);
  font-size: 1.2rem !important;
  font-weight: 600 !important;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cursor {
  cursor: pointer;
}
