.container {
  background: var(--neutral-100);
  max-width: 48%;
  width: 48%;
  border-radius: 0.8rem;
  height: 8rem;
  box-sizing: border-box;
}

.nextVisitTypeSelector {
  margin-top: 1.6rem;
}
.categoryContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.4rem;
  font-weight: 400;
}

@media only screen and (max-width: 768px) {
  .container {
    max-width: 100%;
    width: 100%;
    border-radius: 0.8rem;
    padding: 1.2rem 1.6rem;
    box-sizing: border-box;
  }
}
