.closeIconContainer {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 1.8rem;
  background: var(--neutral-100);
  cursor: pointer;
}
