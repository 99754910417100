.container {
  border-bottom: 0.1rem solid var(--gray-20);
  background: var(--neutral-100);
  padding: 1.2rem 1.6rem;
  cursor: pointer;
}

.dischargeContainer {
  background-color: var(--warm-neutral-50);
}

.active {
  background-color: var(--warm-neutral-50) !important;
}

.patientDetail,
.roomCaseHistory {
  display: flex;
  justify-content: space-between;
}

.roomCaseHistory {
  margin-top: 0.6rem !important;
}

.patientName {
  color: var(--black-900);
  font-size: 1.4rem !important;
  font-weight: 400 !important;
}

.caseHistory {
  color: var(--gray-400);
  font-size: 1.2rem !important;
  font-weight: 400 !important;
}

.admittedDateDuration {
  color: var(--gray-400);
  font-size: 1.4rem !important;
  font-weight: 400 !important;
  display: flex;
  align-items: center;
}

.roomNo {
  border-radius: 1.5rem;
  background: var(--gray-50);
  padding: 0.4rem 0.8rem;
  color: var(--gray-500);
  font-size: 1.2rem !important;
  font-weight: 400 !important;
}

.dischargedPatient {
  background: var(--peach-100);

}