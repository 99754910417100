.container {
  background: var(--neutral-100);
  padding: 1.2rem 1.2rem 1.6rem 1.2rem;
  min-height: 8rem;
  height: auto;
}

.activeContainer {
  padding: 1.2rem 0rem 1.6rem 0rem;
  margin: 1.7rem 0rem;
  max-width: 100%;
  width: 100%;
  border-radius: 0.8rem;
  min-height: 9rem;
  height: auto;
}

.container > p {
  text-align: left;
}
.activeContainer > p {
  margin-bottom: 0.5rem;
}
.inputBtnContainer {
  margin-top: 1.2rem;
}

.activeInputBtnContainer {
  width: 100%;
}

.activeInputBtnContainer > button {
  margin-top: 1rem;
}

.inputWithListContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}
.inputWithListContainer2 {
  color: var(--gray-400);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.1rem;
  text-align: justify;
  width: fit-content !important;
}
.enteredOutput {
  color: var(--gray-700);
  font-size: 1.4rem;
  font-weight: 400;
  text-align: justify;
  width: 100%;
  border: none;
  background: transparent;
  resize: none;
  outline: none;
  font-family: inherit;
}

.enteredOutput:focus {
  outline: none;
}

.searchSuggestionContainer {
  padding: 0rem 1.6rem;
  background-color: var(--blue-gray-100);
}

@media only screen and (max-width: 768px) {
  .container {
    max-width: 100%;
    width: 100%;
  }

  .activeContainer {
    max-width: 100%;
    width: 100%;
  }
}
