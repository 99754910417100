.diseaseContainer {
  text-align: center;
  border-radius: 0.8rem;
  padding: 2rem 0rem;
  min-width: 10.2rem;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 3.2rem;
  max-width: 100%;
  flex-wrap: wrap;
}

.diseaseCard {
  cursor: pointer;
}

@media screen and (max-width: 65rem) {
  .diseaseContainer {
    gap: 1.2rem;
  }
}
