.container {
  background-color: var(--neutral-100);
  height: 100%;
}
.medicalRecord {
  margin-top: 3.6rem;
  padding: 0rem 1.6rem;
}
.medicalRecord > img {
  border-radius: 1rem;
  width: 100%;
}

.img-wrap {
  height: 100%;
  width: 100%;
  object-fit: contain;
  aspect-ratio: 16 / 9;
}
