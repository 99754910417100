.container {
  width: 100% !important;
  max-height: 80rem;
}
.inputContainer {
  margin: 1.6rem 0rem !important;
}
.container > p,
.inputContainer,
.btnContainer {
  padding: 0rem 1.6rem !important;
}
.btnContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cancelBtn {
  margin-right: 1rem !important;
  width: 100%;
}
.accordionContainer {
  background: var(--neutral-100);
  padding: 1.2rem 1.6rem !important;
}
.mealTypeContainer {
  margin-bottom: 1.6rem !important;
}

.mealTypeChipContainer {
  margin: 1.6rem 0rem 1.2rem 0rem !important;
}

.freqDurationContainer {
  margin: 1.6rem 0rem 1.6rem 0rem !important;
}
.selectedMedicineFrequencySlider {
  width: 90%;
  justify-self: center;
}

.freqDurationInput {
  border: none;
  outline: none;
  font-family: "Poppins";
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--gray-700);
}
