.container {
  padding: 1.6rem 1.2rem 2rem 1.2rem !important;
}
.container > h4 {
  color: var(--gray-700);
  font-size: 1.6rem !important;
  font-weight: 500 !important;
}
.iconContainer {
  margin: 1.6rem 0rem 2rem 0rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.iconContainer > div {
  margin-left: 2.4rem !important;
}
.footerText {
  color: var(--gray-500);
  font-family: Roboto;
  font-size: 1.2rem !important;
  font-weight: 400 !important;
}
