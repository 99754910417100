.subContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.cardSelectionContainer {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1.2rem;
}

@media screen and (max-width: 65rem) {
  .cardSelectionContainer {
    width: 10rem;
  }
}
