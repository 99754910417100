.categoryName {
  padding: 0.4rem 0.8rem;
  border-radius: 1.2rem;
  background: var(--gray-20);
  color: var(--black-900);
  font-family: Roboto;
  text-transform: capitalize !important;
  font-size: 1.2rem !important;
  font-weight: 500 !important;
  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.imageOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.3) 60%,
    rgba(0, 0, 0, 0) 100%
  );
  pointer-events: none;
}

.recordUploadedDate {
  margin-top: 0.2rem !important;
  color: var(--neutral-100);
  font-size: 1.2rem !important;
  font-weight: 700 !important;
  text-transform: capitalize;
}
.medicalRecordContainer {
  max-height: 12rem;
  max-width: 12rem;
  position: relative;
}
.medicalRecordContainerMobile {
  position: relative;
}
.medicalRecordList > img {
  width: 100%;
  height: 36rem;
}
.medicalRecordGallery > img {
  width: 100%;
  height: 12rem;
}
.medicalRecordDateCategoryContainer {
  position: absolute;
  z-index: 10;
  bottom: 1.1rem;
  left: 0.5rem;
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  width: 100%;
}
.prescIcon {
  position: absolute;
  right: 8%;
  bottom: -4px;
}
.downloadIconContainer {
  position: absolute;
  top: 0.3rem;
  right: 3%;
  cursor: pointer;
}
.selected {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: var(--orange-500);
  opacity: 0.6;
}
.notSelected {
  border: 0.1rem solid var(--neutral-200);
}
