.profileContainer {
  padding: 1.6rem;
}

.profileInputField {
  margin: 0rem 0rem 1.6rem 0rem !important;
}
.selectorText {
  color: var(--orange-500);
  font-size: 1.4rem !important;
  font-weight: 500;
  background-color: var(--neutral-100);
}
.createProfile {
  color: var(--black-900);
  font-size: 1.4rem !important;
  font-weight: 400 !important;
}
.profileContainer > h6 {
  margin-bottom: 1.6rem !important;
  color: var(--black-900);
  font-size: 1.4rem !important;
  font-weight: 500 !important;
  text-transform: capitalize;
}
.profileContainer > button {
  margin-top: 1.6rem !important;
}

@media screen and (max-width: 65rem) {
  .profileContainer {
    width: 100%;
  }
}
