.btn {
  width: 100%;
  height: 4.8rem;
  padding: 0.6rem 0rem;
  border-radius: 0.8rem !important;
  font-size: 1.4rem !important;
  text-transform: none !important;
  font-weight: 500 !important;
}
.orangeDefault {
  border: 0.1rem solid var(--orange-500) !important;
  background: var(--orange-500) !important;
  color: var(--neutral-100) !important;
}
.whiteBtn {
  border: 0.1rem solid var(--gray-200) !important;
  background: var(--neutral-100) !important;
  color: var(--orange-500) !important;
  padding: 6px 12px !important;
}
.orangeRounded {
  width: fit-content;
  padding: 0.6rem 1.2rem !important;
  height: 100%;
  border-radius: 1.2rem !important;
  font-size: 1rem !important;
}
.greyedBtn {
  border-radius: 0.8rem;
  background: var(--gray-400) !important;
  color: var(--neutral-100) !important;
}
.text {
  width: max-content;
  color: var(--gray-500) !important;
  font-size: 1.2rem !important;
  font-weight: 500 !important;
}
