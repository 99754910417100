.textContainer {
  width: 100%;
}
.textContainer > h4 {
  color: var(--orange-500);
  font-size: 2.4rem;
  font-weight: 600;
  text-transform: capitalize;
}

.textContainer > p {
  margin-top: 0.4rem;
  color: var(--gray-600);
  font-size: 1.4rem;
  font-weight: 400;
}
.inputContainer {
  margin-left: 8rem;
  width: 100%;
}
.inputContainer > div {
  margin-bottom: 2rem;
}
.submitBtn {
  text-align: right;
}
.submitBtn > button {
  width: 17.4rem;
}
