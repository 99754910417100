.avilabilityTopContainer {
  padding: 2.4rem 2.4rem 1.2rem 2.4rem;
  background: var(--blue-gray-100);
  text-align: left;
  display: flex;
  justify-content: space-between;
}
.avilabilityTopContainer > h4 {
  color: var(--black-900);
  font-size: 2.4rem;
  font-weight: 500;
}
.availabilityListingCreateUpdateContainer {
  display: flex;
  justify-content: space-between;
}
.availabilityListing {
  width: 35%;
  padding: 1.2rem;
  border-right: 0.1rem solid var(--gray-200);
}
.availabilityListing > div {
  margin: 0rem;
}
.availabilityCreateUpdateContainer {
  width: 65%;
  padding-left: 0.3rem;
}
.addNewAvailability > button:first-of-type {
  padding: 0rem 1.5rem;
}
.addNewAvailability {
  display: flex;
}
.closebutton {
  margin-left: 1rem;
  border-radius: 100%;
  background-color: var(--neutral-100);
  height: 4rem;
  width: 4rem;
  align-self: center;
}
