.blogContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.blogMetaContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.6rem;
}

.blogContent h1 {
  font-size: 3.2rem;
  font-weight: 600;
  line-height: 4.8rem;
  color: var(--black-900);
}

.blogContent h2 {
  font-size: 24px;
  font-weight: 600;
  color: var(--black-900);
}

.blogContent p {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 400;
  color: var(--black-900);
}

.blogImageContainer {
  width: 100%;
}

.blogContent img {
  width: 100%;
  max-height: 37.1rem;
  border-radius: 1.6rem;
  object-fit: cover;
}

.blogContent a {
  color: var(--black-900);
  text-decoration: underline;
}
.blogImageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blogContent ul,
.blogContent ol {
  margin: 1.6rem 0;
  padding-left: 2.4rem;
}

.blogContent li {
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: var(--black-900);
}

.blogContent blockquote {
  margin: 1.6rem 0;
  padding: 1.6rem;
  background-color: var(--gray-100);
  border-left: 0.4rem solid var(--black-900);
  font-style: italic;
  color: var(--black-700);
}

.blogContent pre {
  background-color: var(--gray-200);
  padding: 1.6rem;
  border-radius: 0.8rem;
  overflow-x: auto;
}

.blogContent code {
  font-family: "Courier New", Courier, monospace;
  background-color: var(--gray-100);
  padding: 0.2rem 0.4rem;
  border-radius: 0.4rem;
}

@media screen and (max-width: 65rem) {
  .blogContent h1 {
    font-size: 2.4rem;
    line-height: 3.2rem;
  }

  .blogContent h2 {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  .blogContent p {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
}
